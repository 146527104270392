import React from "react";
import { Outlet, Navigate } from "react-router-dom";

import { useMsal } from "@azure/msal-react";

const PrivateRouters = () => {
	const { accounts } = useMsal();
	let auth = { Token: false };
	return accounts.length > 0 ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRouters;
