import React from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import IconResolver from "../utils/IconResolver";
import { Link } from "react-router-dom";
import Container from "@mui/material/Container"; // Added import statement

const Item = styled(Paper)(({ theme }) => ({
	...theme.typography.body2,
	textAlign: "center",
	color: theme.palette.text.secondary,
	height: 120,
	lineHeight: "60px",
}));

const lightTheme = createTheme({ palette: { mode: "light" } });

function Onderhoud() {
	return (
		<Container maxWidth="lg" sx={{ mt: 10 }}>
			<Grid container spacing={2} sx={{ marginLeft: 10 }}>
				<Typography variant="h4" color="initial">
					Onderhoud stamtabellen
				</Typography>
				{[lightTheme].map((theme, index) => (
					<Grid item xs={6} key={index} sx={{ marginTop: 10 }}>
						<ThemeProvider theme={theme}>
							<Box
								sx={{
									p: 2,
									borderRadius: 2,
									bgcolor: "background.default",
									display: "grid",
									gridTemplateColumns: { md: "1fr 1fr" },
									gap: 2,
								}}
							>
								{itemList.pageLinks.map((item) => (
									<Item key={item.id} elevation={24} sx={{ minHeight: "100px" }}>
										<Button
											aria-label={item.name}
											component={Link}
											to={item.link}
										>
											<IconResolver
												iconName={item.icon}
												fontSize={item.iconsize}
											/>
											{"  "}
											{item.name}
										</Button>
										<Typography variant="body2" color="initial">
											{item.description}
										</Typography>
									</Item>
								))}
							</Box>
						</ThemeProvider>
					</Grid>
				))}
			</Grid>
		</Container>
	);
}

export default Onderhoud;

const itemList = {
	pageLinks: [
		{
			id: 1,
			name: "Gebruikers",
			link: "/onderhoud/gebruikers",
			icon: "ManageAccounts",
			iconsize: "medium",
			description:
				"Beheer van gebruikers, details en rollen voor de betreffende gebruikers.",
		},
		{
			id: 2,
			name: "Dienstverleners zorg",
			link: "/onderhoud/dienstverleners",
			icon: "VaccinesTwoTone",
			iconsize: "medium",
			description:
				"Wijziging stamgegevens huisartsen, tandartsen, apotheek en verloskundige hulp",
		},
		{
			id: 3,
			name: "Takenlijst",
			link: "/takenlijst",
			icon: "PlaylistAddCheckRounded",
			iconsize: "medium",
			description:
				"Aanpassen van de trajectlijst (takenlijst) voor alle statushouders.",
		},
		{
			id: 4,
			name: "Trajecten",
			link: "/trajecten",
			icon: "ForkLeftRounded",
			iconsize: "medium",
		},
	],
};
