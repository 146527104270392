import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import { CardContent, Grid, IconButton, Snackbar, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Tooltip } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import axios from "axios";
import Alert from "@mui/material/Alert";
import CheckIcon from "@mui/icons-material/Check";
import IconResolver from "../components/IconResolver";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import EditIcon from '@mui/icons-material/Edit';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import DescriptionIcon from '@mui/icons-material/Description';

function FamilieOverview() {
  const navigate = useNavigate();
  const location = useLocation();
  const [members, setMembers] = useState([]);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const { from } = location.state || {};
  const familieId = from.id;
  console.log(from);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const { instance, accounts } = useMsal();
  const GetAccessTokens = async () => {
    const request = {
      scopes: ["api://e8ee6ebf-41ff-4a7a-8d4b-2b2b4aa05d8e/User.Read"],
      account: accounts[0],
    };
    try {
      const response = await instance.acquireTokenSilent(request);
      return response.accessToken;
    } catch (e) {
      if (e instanceof InteractionRequiredAuthError) {
        return instance.acquireTokenRedirect(request);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!from?.id) {
        console.error("No family ID provided");
        navigate("/families");
        return;
      }

      try {
        const token = await GetAccessTokens();
        const userEmail = accounts[0]?.username;
        console.log(from)
        const response = await axios.get(`/api/family/${from.id}/members`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "X-User-Email": userEmail,
          },
        });

        if (response.data?.family_members) {
          setMembers(response.data.family_members);
          if (response.data.family_members.length > 0) {
            setMessage("Familieleden succesvol opgehaald");
            setSeverity("success");
          } else {
            setMessage("Er zijn nog geen familieleden toegevoegd aan deze familie. Maak eerst familieleden aan...");
            setSeverity("warning");
          }
        } else {
          setMessage("Er zijn nog geen familieleden toegevoegd aan deze familie. Maak eerst familieleden aan...");
          setSeverity("warning");
          setMembers([]);
        }
      } catch (error) {
        console.error("Error fetching family members:", error);
        setMessage("Er was een verbindingsfout met de clientendatabase. Als dit herhaalt, neem contact op met de systeembeheerder");
        setSeverity("error");
        setMembers([]);
      } finally {
        setOpen(true);
      }
    };

    fetchData();
  }, [from?.id, navigate, instance, accounts]);

  const handleDoubleClick = (row) => {
    navigate("/memberchecklist", { state: { from: row } });
  };

  const familyAction = {
    operations: [
      {
        id: 1,
        action: "Familielid toevoegen",
        icon: "AddCircleTwoTone",
        iconsize: "medium",
        isactive: true,
        link: "/newfamilymember",
      },
      {
        id: 2,
        action: "Naar logboek",
        icon: "Description",
        iconsize: "medium",
        isactive: true,
        link: "/family_logboek",
      },
      {
        id: 3,
        action: "Familiegegevens bijwerken",
        icon: "Edit",
        iconsize: "medium",
        isactive: true,
        link: "/editfamily",
      },
      {
        id: 4,
        action: "Bestanden",
        icon: "CloudUpload",
        iconsize: "medium",
        isactive: true,
        link: "/family_files",
      },
    ],
  };

  const handleNavigation = (link) => {
    if (link === "/editfamily") {
      navigate(link, { state: { from } });
    } else {
      navigate(link, { state: { from } });
    }
  };

  return (
    <Container 
      maxWidth="lg" 
      sx={{ 
        py: 3,
        px: { xs: 2, sm: 3 },
        mx: 'auto',
        width: '80%'
      }}
    >
      <Box
        sx={{
          width: "100%",
          mt: 10,
          maxWidth: '100%',
          overflowX: 'auto'
        }}
      >
        <Box sx={{ maxWidth: '100%' }}>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={open}
            autoHideDuration={2500}
            onClose={handleClose}
          >
            <Alert
              severity={severity}
              onClose={handleClose}
              variant="filled"
              sx={{ width: "100%" }}
            >
              {message}
            </Alert>
          </Snackbar>
        </Box>

        <Paper 
          elevation={2} 
          sx={{ 
            p: { xs: 2, sm: 3, md: 4 }, 
            borderRadius: { xs: 1, sm: 2 },
            mb: { xs: 3, sm: 4, md: 5 }  // Margin bottom for spacing between sections
          }}
        >
          <Grid container spacing={3} sx={{ maxWidth: '100%' }}>
            {/* Header Section */}
            <Grid item xs={12}>
              <Box sx={{ 
                display: 'flex', 
                flexDirection: { xs: 'column', sm: 'row' },
                justifyContent: 'space-between',
                alignItems: { xs: 'flex-start', sm: 'center' },
                gap: 2,
                mb: 3,
                maxWidth: '100%'
              }}>
                <Box>
                  <Typography variant="h4" gutterBottom>
                    Familie {from.familyName}
                  </Typography>
                  <Typography variant="subtitle1" color="text.secondary">
                    {from.familyStreet} {from.familyHousenumber}, {from.familyPostalcode} {from.familyCity}
                  </Typography>
                </Box>
                <Tooltip title="Terug naar overzicht">
                  <Button
                    variant="outlined"
                    startIcon={<ArrowBackIcon />}
                    onClick={() => navigate(-1)}
                    sx={{
                      borderRadius: 2,
                      px: 3,
                      py: 1,
                      borderWidth: '2px',
                      '&:hover': {
                        borderWidth: '2px',
                        transform: 'translateY(-2px)',
                        boxShadow: 1,
                      },
                      transition: 'all 0.2s ease-in-out',
                    }}
                  >
                    Terug
                  </Button>
                </Tooltip>
              </Box>
            </Grid>

            {/* Action Buttons */}
            <Grid item xs={12}>
              <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center',
                mb: 2,
                maxWidth: '100%'
              }}>
                {[
                  {
                    id: 1,
                    action: "Familielid toevoegen",
                    icon: <PersonAddIcon />,
                    link: "/newfamilymember",
                    color: "primary",
                  },
                  {
                    id: 2,
                    action: "Naar logboek",
                    icon: <DescriptionIcon />,
                    link: "/family_logboek",
                    color: "info",
                  },
                  {
                    id: 3,
                    action: "Familiegegevens bijwerken",
                    icon: <EditIcon />,
                    link: "/editfamily",
                    color: "success",
                  },
                  {
                    id: 4,
                    action: "Bestanden",
                    icon: <CloudUploadIcon />,
                    link: "/family_files",
                    color: "warning",
                  },
                ].map((operation) => (
                  <Tooltip key={operation.id} title={operation.action}>
                    <Paper
                      elevation={2}
                      sx={{
                        borderRadius: 2,
                        overflow: 'hidden',
                        transition: 'all 0.2s ease-in-out',
                        '&:hover': {
                          transform: 'translateY(-2px)',
                          boxShadow: 3,
                        },
                      }}
                    >
                      <Button
                        onClick={() => handleNavigation(operation.link)}
                        sx={{
                          px: 3,
                          py: 1.5,
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                          color: `${operation.color}.main`,
                          bgcolor: `${operation.color}.lighter`,
                          '&:hover': {
                            bgcolor: `${operation.color}.light`,
                          },
                        }}
                      >
                        {operation.icon}
                        <Typography 
                          variant="button" 
                          sx={{ 
                            textTransform: 'none',
                            fontWeight: 500,
                            fontSize: '0.9rem',
                          }}
                        >
                          {operation.action}
                        </Typography>
                      </Button>
                    </Paper>
                  </Tooltip>
                ))}
              </Box>
            </Grid>

            {/* Family Members Table */}
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                Familieleden
              </Typography>
              <TableContainer sx={{ maxWidth: '100%', overflowX: 'auto' }}>
                <Table sx={{ 
                  minWidth: { xs: '100%', sm: 480, md: 600 }
                }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Voornaam</TableCell>
                      <TableCell>Achternaam</TableCell>
                      <TableCell>Relatie</TableCell>
                      <TableCell>BSN</TableCell>
                      <TableCell>Geboortedatum</TableCell>
                      <TableCell>Telefoon</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Bankrekeningnummer</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {members.map((row) => (
                      <TableRow
                        key={row.id}
                        hover
                        onClick={() => handleDoubleClick(row)}
                        style={{ cursor: 'pointer' }}
                      >
                        <TableCell>{row.familyMemberFirstName}</TableCell>
                        <TableCell>{row.familyMemberSurname}</TableCell>
                        <TableCell>{row.familyMemberRelationship}</TableCell>
                        <TableCell>{row.familyMemberSocialNumber}</TableCell>
                        <TableCell>{row.familyMemberBirthdate}</TableCell>
                        <TableCell>{row.familyMemberPhone}</TableCell>
                        <TableCell>{row.familyMemberEmail}</TableCell>
                        <TableCell>{row.familyMemberBankAccount}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Container>
  );
}

export default FamilieOverview;
