import React, { useState } from "react";
import { useAuth } from "../authentication/useAuth"; // Your existing MSAL auth hook
import axios from "axios";
import { 
  Button, 
  Snackbar, 
  Alert, 
  CircularProgress, 
  Box, 
  Typography 
} from "@mui/material";
import FileDownloadIcon from '@mui/icons-material/FileDownload';

export const useExportFamilies = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { getAccessToken } = useAuth();

  const handleExport = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const token = await getAccessToken();

      const response = await axios({
        url: "/api/export-families",
        method: "GET",
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/vnd.ms-excel",
        },
      });

      // Handle the file download
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Get filename from response headers
      const contentDisposition = response.headers["content-disposition"];
      let filename = "families_export.xlsx";
      if (contentDisposition) {
        const matches = contentDisposition.match(
          /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
        );
        if (matches && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
        }
      }

      // Create and trigger download
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();

      // Cleanup
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (err) {
      console.error("Export error:", err);
      setError(err.message || "Failed to export families");
    } finally {
      setIsLoading(false);
    }
  };

  return {
    handleExport,
    isLoading,
    error,
  };
};

// Component using the hook
const ExportFamiliesButton = () => {
  const { handleExport, isLoading, error } = useExportFamilies();
  const [showError, setShowError] = useState(false);

  React.useEffect(() => {
    if (error) {
      setShowError(true);
    }
  }, [error]);

  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowError(false);
  };

  return (
    <>
      <Button
        onClick={handleExport}
        disabled={isLoading}
        startIcon={
          isLoading ? (
            <CircularProgress size={20} color="inherit" />
          ) : (
            <FileDownloadIcon />
          )
        }
        sx={{
          px: 3,
          py: 1.5,
          bgcolor: 'info.lighter',
          color: 'info.main',
          '&:hover': {
            bgcolor: 'info.light',
          },
          '&.Mui-disabled': {
            bgcolor: 'info.lighter',
            color: 'info.main',
            opacity: 0.7,
          },
          minWidth: '180px',
          display: 'flex',
          alignItems: 'center',
          gap: 1,
        }}
      >
        <Typography 
          variant="button" 
          sx={{ 
            textTransform: 'none',
            fontWeight: 500,
            fontSize: '0.9rem',
          }}
        >
          {isLoading ? "Bezig met exporteren..." : "Exporteren naar Excel"}
        </Typography>
      </Button>

      <Snackbar
        open={showError}
        autoHideDuration={6000}
        onClose={handleCloseError}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleCloseError} 
          severity="error" 
          variant="filled"
          sx={{ width: '100%' }}
        >
          {error || "Er is een fout opgetreden bij het exporteren"}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ExportFamiliesButton;
