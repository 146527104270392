import React, { useEffect, useState, useReducer, useCallback } from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import axios from "axios";
import Button from "@mui/material/Button";
import { useNavigate, useLocation } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

function EditFamily() {
  const navigate = useNavigate();
  const today = dayjs();
  const location = useLocation();
  const { from } = location.state;
  const [users, setUsers] = useState(null);
  const [data, setData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      familyName: from.familyName,
      familyStreet: from.familyStreet,
      familyHousenumber: from.familyHousenumber,
      familyPostalcode: from.familyPostalcode,
      familyCity: from.familyCity,
      familyPhone: from.familyPhone,
      familyCellPhone: from.familyCellPhone,
      familyEmail: from.familyEmail,
      familyDateInflo: from.familyDateInflo ? dayjs(from.familyDateInflo) : today,
      familyVolunteer: from.familyVolunteer,
    }
  );

  const { instance, accounts } = useMsal();
  const getAccessToken = useCallback(async () => {
    const request = {
      scopes: ["api://e8ee6ebf-41ff-4a7a-8d4b-2b2b4aa05d8e/User.Read"],
      account: accounts[0],
    };
    try {
      const response = await instance.acquireTokenSilent(request);
      return response.accessToken;
    } catch (e) {
      if (e instanceof InteractionRequiredAuthError) {
        return instance.acquireTokenRedirect(request);
      }
    }
  }, [instance, accounts]);

  const handleOnChange = useCallback((e) => {
    const { name, value } = e.target;
    setData({ [name]: value });
  }, []);

  const handleClick = useCallback(async () => {
    try {
      const accessToken = await getAccessToken();
      const formattedData = {
        ...data,
        familyDateInflo: dayjs(data.familyDateInflo).format("YYYY-MM-DD")
      };

      const response = await axios({
        method: "PUT",
        url: "/api/family/" + from.id,
        data: {
          familyName: formattedData.familyName,
          familyStreet: formattedData.familyStreet,
          familyHousenumber: formattedData.familyHousenumber,
          familyPostalcode: formattedData.familyPostalcode,
          familyCity: formattedData.familyCity,
          familyPhone: formattedData.familyPhone,
          familyCellPhone: formattedData.familyCellPhone,
          familyEmail: formattedData.familyEmail,
          familyDateInflo: formattedData.familyDateInflo,
          familyVolunteer: formattedData.familyVolunteer,
        },
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      });
      
      navigate("/families");
    } catch (error) {
      console.error("Error updating family:", error);
    }
  }, [data, from.id, getAccessToken, navigate]);

  const getUserInfo = useCallback(async () => {
    try {
      const accessToken = await getAccessToken();
      const response = await axios({
        method: "GET",
        url: "/api/onderhoud/userlist",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      });
      
      setUsers(response.data.users);
    } catch (error) {
      console.error("Error fetching user info:", error);
    }
  }, [getAccessToken]);

  useEffect(() => {
    getUserInfo();
  }, [getUserInfo]);

  return (
    <Box
      sx={{
        minHeight: "100vh",
        backgroundColor: "#f5f5f5",
        mt: 10,
        py: 4,
        px: { xs: 2, sm: 4, md: 6 },
      }}
    >
      <Paper
        elevation={3}
        sx={{
          maxWidth: 1000,
          mx: "auto",
          p: { xs: 2, sm: 3, md: 4 },
          borderRadius: 2,
          backgroundColor: "#ffffff",
        }}
      >
        <Box sx={{ mb: 4, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Box>
            <Typography variant="h4" color="primary" gutterBottom sx={{ fontWeight: 600 }}>
              Familie {from.familyName}
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
              Bewerk familiegegevens
            </Typography>
          </Box>
          <Button
            variant="outlined"
            onClick={() => navigate(-1)}
            sx={{
              borderRadius: 2,
              px: 3,
              py: 1,
            }}
          >
            Terug
          </Button>
        </Box>

        <Grid container spacing={3}>
          {/* Personal Information */}
          <Grid item xs={12}>
            <Typography variant="h6" color="primary" sx={{ mb: 2, fontWeight: 500 }}>
              Persoonlijke Informatie
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="familyName"
                  name="familyName"
                  label="Familienaam"
                  variant="outlined"
                  value={data.familyName}
                  onChange={handleOnChange}
                  sx={{ mb: 0 }}
                />
              </Grid>
            </Grid>
          </Grid>

          {/* Address Information */}
          <Grid item xs={12}>
            <Typography variant="h6" color="primary" sx={{ mb: 2, mt: 2, fontWeight: 500 }}>
              Adresgegevens
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={8}>
                <TextField
                  fullWidth
                  id="familyStreet"
                  name="familyStreet"
                  label="Straatnaam"
                  variant="outlined"
                  value={data.familyStreet}
                  onChange={handleOnChange}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  id="familyHousenumber"
                  name="familyHousenumber"
                  label="Huisnummer"
                  variant="outlined"
                  value={data.familyHousenumber}
                  onChange={handleOnChange}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  id="familyPostalcode"
                  name="familyPostalcode"
                  label="Postcode"
                  variant="outlined"
                  value={data.familyPostalcode}
                  onChange={handleOnChange}
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <TextField
                  fullWidth
                  id="familyCity"
                  name="familyCity"
                  label="Woonplaats"
                  variant="outlined"
                  value={data.familyCity}
                  onChange={handleOnChange}
                />
              </Grid>
            </Grid>
          </Grid>

          {/* Contact Information */}
          <Grid item xs={12}>
            <Typography variant="h6" color="primary" sx={{ mb: 2, mt: 2, fontWeight: 500 }}>
              Contactgegevens
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="familyPhone"
                  name="familyPhone"
                  label="Telefoonnummer (vast)"
                  variant="outlined"
                  value={data.familyPhone}
                  onChange={handleOnChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="familyCellPhone"
                  name="familyCellPhone"
                  label="Mobiel nummer"
                  variant="outlined"
                  value={data.familyCellPhone}
                  onChange={handleOnChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="familyEmail"
                  name="familyEmail"
                  label="E-mailadres"
                  variant="outlined"
                  value={data.familyEmail}
                  onChange={handleOnChange}
                />
              </Grid>
            </Grid>
          </Grid>

          {/* Additional Information */}
          <Grid item xs={12}>
            <Typography variant="h6" color="primary" sx={{ mb: 2, mt: 2, fontWeight: 500 }}>
              Aanvullende Informatie
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Datum instroom"
                    value={data.familyDateInflo}
                    onChange={(e) =>
                      handleOnChange({
                        target: { value: e, name: "familyDateInflo" },
                      })
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        variant="outlined"
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Maatschappelijk begeleider</InputLabel>
                  <Select
                    id="familyVolunteer"
                    name="familyVolunteer"
                    value={data.familyVolunteer}
                    onChange={handleOnChange}
                    label="Maatschappelijk begeleider"
                  >
                    {users &&
                      users.map((user) => (
                        <MenuItem key={user.id} value={user.id}>
                          {user.first_name} {user.last_name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Box sx={{ mt: 4, display: "flex", justifyContent: "flex-end", gap: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClick}
            sx={{
              px: 4,
              py: 1.5,
              borderRadius: 2,
              boxShadow: 2,
              "&:hover": {
                boxShadow: 4,
                transform: "translateY(-2px)",
              },
              transition: "all 0.2s ease-in-out",
            }}
          >
            Opslaan
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}

export default EditFamily;
