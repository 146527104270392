import React, { useReducer, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import Typography from "@mui/material/Typography";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Button,
  Paper,
  Box,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import axios from "axios";
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";

dayjs.extend(utc);
dayjs.extend(timezone);

function NewFamilyMember() {
  const location = useLocation();
  const { from } = location.state;
  const navigate = useNavigate();
  console.log(from);

  const { instance, accounts } = useMsal();

  const GetAccessTokens = async () => {
    const request = {
      scopes: ["api://e8ee6ebf-41ff-4a7a-8d4b-2b2b4aa05d8e/User.Read"],
      account: accounts[0],
    };
    console.log(request);
    try {
      const response = await instance.acquireTokenSilent(request);
      console.log(response);
      return response.accessToken;
    } catch (e) {
      if (e instanceof InteractionRequiredAuthError) {
        return instance.acquireTokenRedirect(request);
      }
    }
  };
  const getAccessTokens = GetAccessTokens();

  // Service providers state
  const [serviceProviders, setServiceProviders] = useState({
    tandartsen: [],
    huisartsen: [],
    verloskundigen: [],
    apotheken: []
  });

  // Add service provider IDs to the data state
  const [data, setData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      familyId: from.id,
      familyMemberFirstName: "",
      familyMemberSurname: "",
      familyMemberSocialNumber: "",
      familyMemberBankAccount: "",
      familyMemberEmail: "",
      familyMemberPhone: "",
      familyMemberGender: "",
      familyMemberMotherTongue: "",
      familyMemberNationality: "",
      familyEmail: "",
      familyVolunteer: "",
      familyMemberBirthdate: dayjs(),
      plaatsingDate: dayjs(),
      datumNederland: dayjs(),
      datumWbD: dayjs(),
      datumUitstroom: dayjs(),
      // Service provider selections
      familyMemberHuisartsId: "",
      familyMemberTandartsId: "",
      familyMemberApotheekId: "",
      familyMemberVerloskundigeId: "",
    }
  );

  // Fetch service providers
  const fetchServiceProviders = async () => {
    const token = await getAccessTokens;
    try {
      const [tandartsenRes, huisartsenRes, verloskundigenRes, apothekenRes] = await Promise.all([
        axios.get('/api/onderhoud/tandartsen', {
          headers: { Authorization: `Bearer ${token}` }
        }),
        axios.get('/api/onderhoud/huisartsen', {
          headers: { Authorization: `Bearer ${token}` }
        }),
        axios.get('/api/onderhoud/verloskundigen', {
          headers: { Authorization: `Bearer ${token}` }
        }),
        axios.get('/api/onderhoud/apotheken', {
          headers: { Authorization: `Bearer ${token}` }
        })
      ]);

      setServiceProviders({
        tandartsen: tandartsenRes.data.tandartsen || [],
        huisartsen: huisartsenRes.data.huisartsen || [],
        verloskundigen: verloskundigenRes.data.verloskundigen || [],
        apotheken: apothekenRes.data.apotheken || []
      });
    } catch (error) {
      console.error('Error fetching service providers:', error);
    }
  };

  useEffect(() => {
    fetchServiceProviders();
  }, []);

  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setData({ [name]: value });
    console.log(data);
  };

  const handleDateformat = (data) => {
    data.familyMemberBirthdate = dayjs(data.familyMemberBirthdate).format(
      "YYYY-MM-DD"
    );
    data.plaatsingDate = dayjs(data.plaatsingDate).format("YYYY-MM-DD");
    data.datumNederland = dayjs(data.datumNederland).format("YYYY-MM-DD");
    data.datumWbD = dayjs(data.datumWbD).format("YYYY-MM-DD");
    data.datumUitstroom = dayjs(data.datumUitstroom).format("YYYY-MM-DD");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    switch (true) {
      case validateEmail(data.familyMemberEmail) === false:
        setEmailError(true);
        break;
      case validateEmail(data.familyMemberEmail) === true:
        setEmailError(false);
        break;

      default:
    }

    if (validateEmail(data.familyMemberEmail) === true) {
      const formattedData = { ...data };
      handleDateformat(formattedData);

      const token = await getAccessTokens;
      try {
        // Create family member
        const memberResponse = await axios({
          method: "POST",
          url: "/api/addmember",
          data: formattedData,
          headers: {
            Authorization: "Bearer " + token,
          },
        });

        if (memberResponse.data?.familyMember?.id) {
          try {
            await axios({
              method: "POST",
              url: "/api/updatemembermetadata/" + memberResponse.data.familyMember.id,
              data: {
                familyMemberHuisartsId: formattedData.familyMemberHuisartsId,
                familyMemberTandartsId: formattedData.familyMemberTandartsId,
                familyMemberApotheekId: formattedData.familyMemberApotheekId,
                familyMemberVerloskundigeId: formattedData.familyMemberVerloskundigeId,
              },
              headers: {
                Authorization: "Bearer " + token,
              },
            });
          } catch (error) {
            console.error("Error updating member metadata:", error);
            // Continue with navigation even if metadata update fails
          }
        }

        navigate(-1);
      } catch (error) {
        console.error('Error creating family member:', error);
      }
    }
  };

  const validateEmail = (email) => {
    const regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    console.log(regex.test(email));
    return regex.test(email);
  };

  return (
    <Container
      maxWidth="xl"
      sx={{
        marginLeft: "5%",
        marginTop: "5%",
        "& .MuiTextField-root": { m: 1, width: "55ch" },
        "& .MuiSelect-root": { m: 1, width: "50ch" },
        "& .MuiFormControl-root": { m: 1, width: "50ch" },
      }}
    >
      <Typography variant="h4" color="initial">
        Familielid toevoegen voor familie {from.familyName}
      </Typography>
      <Grid container spacing={2} direction="row" justify="flex-start">
        <Grid item xs={6}>
          <FormControl>
            <TextField
              id="familyMemberFirstName"
              name="familyMemberFirstName"
              label="Voornaam"
              variant="filled"
              autoComplete="off"
              onChange={handleOnChange}
              required
            ></TextField>
            <TextField
              id="familyMemberSurname"
              name="familyMemberSurname"
              label="Achternaam"
              variant="filled"
              autoComplete="off"
              onChange={handleOnChange}
              required
            ></TextField>
            <TextField
              id="familyMemberSocialNumber"
              name="familyMemberSocialNumber"
              label="BSN"
              variant="filled"
              autoComplete="off"
              onChange={handleOnChange}
              required
            ></TextField>
            <TextField
              id="familyMemberBankAccount"
              name="familyMemberBankAccount"
              label="Bankrekeningnummer"
              variant="filled"
              autoComplete="off"
              onChange={handleOnChange}
            ></TextField>
            <TextField
              id="familyMemberEmail"
              name="familyMemberEmail"
              label="Persoonlijk emailadres"
              variant="filled"
              autoComplete="off"
              onChange={handleOnChange}
              error={emailError}
              helperText={emailError ? "Ongeldig emailadres" : ""}
              required
            ></TextField>
            <TextField
              id="familyMemberPhone"
              name="familyMemberPhone"
              label="Telefoonnummer (vast of primaire mobiel)"
              variant="filled"
              autoComplete="off"
              onChange={handleOnChange}
            ></TextField>

            <FormControl>
              <InputLabel id="familyMemberGender">
                Geslacht (man/vrouw/neutraal)
              </InputLabel>
              <Select
                labelId="familyMemberGender"
                id="familyMemberGender"
                name="familyMemberGender"
                label="Geslacht (man/vrouw/neutraal)"
                variant="filled"
                onChange={handleOnChange}
                required
              >
                <MenuItem value={10}>Man</MenuItem>
                <MenuItem value={20}>Vrouw</MenuItem>
                <MenuItem value={30}>Neutraal</MenuItem>
              </Select>
            </FormControl>
            <TextField
              id="familyMemberMotherTongue"
              name="familyMemberMotherTongue"
              label="Moedertaal"
              variant="filled"
              autoComplete="off"
              onChange={handleOnChange}
              required
            ></TextField>
            <TextField
              id="familyMemberNationality"
              name="familyMemberNationality"
              label="Oorspronkelijke nationaliteit"
              variant="filled"
              autoComplete="off"
              onChange={handleOnChange}
              required
            ></TextField>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Geboortedatum"
                timezone="Europe/Amsterdam"
                variant="filled"
                value={data.familyMemberBirthdate}
                onChange={(e) =>
                  handleOnChange({
                    target: { value: e, name: "familyMemberBirthdate" },
                  })
                }
              />
              <DatePicker
                label="Koppelingsdatum"
                timezone="Europe/Amsterdam"
                variant="filled"
                value={data.plaatsingDate}
                onChange={(e) =>
                  handleOnChange({
                    target: { value: e, name: "plaatsingDate" },
                  })
                }
              />
              <DatePicker
                label="Datum in NL"
                timezone="Europe/Amsterdam"
                variant="filled"
                value={data.datumNederland}
                onChange={(e) =>
                  handleOnChange({
                    target: { value: e, name: "datumNederland" },
                  })
                }
              />
              <DatePicker
                label="Datum in WBD"
                timezone="Europe/Amsterdam"
                variant="filled"
                value={data.datumWbD}
                onChange={(e) =>
                  handleOnChange({
                    target: { value: e, name: "datumWbD" },
                  })
                }
              />
              <DatePicker
                label="Datum uitstroom"
                timezone="Europe/Amsterdam"
                variant="filled"
                value={data.datumUitstroom}
                onChange={(e) =>
                  handleOnChange({
                    target: { value: e, name: "datumUitstroom" },
                  })
                }
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>

        {/* Service Provider Section */}
        <Grid item xs={12}>
          <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>
            Dienstverleners
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth>
            <InputLabel>Huisarts</InputLabel>
            <Select
              name="familyMemberHuisartsId"
              value={data.familyMemberHuisartsId}
              onChange={handleOnChange}
              label="Huisarts"
            >
              <MenuItem value="">
                <em>Geen huisarts geselecteerd</em>
              </MenuItem>
              {serviceProviders.huisartsen.map((huisarts) => (
                <MenuItem key={huisarts.id} value={huisarts.id}>
                  {huisarts.huisartsPraktijk}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth>
            <InputLabel>Tandarts</InputLabel>
            <Select
              name="familyMemberTandartsId"
              value={data.familyMemberTandartsId}
              onChange={handleOnChange}
              label="Tandarts"
            >
              <MenuItem value="">
                <em>Geen tandarts geselecteerd</em>
              </MenuItem>
              {serviceProviders.tandartsen.map((tandarts) => (
                <MenuItem key={tandarts.id} value={tandarts.id}>
                  {tandarts.tandartsPraktijk}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth>
            <InputLabel>Apotheek</InputLabel>
            <Select
              name="familyMemberApotheekId"
              value={data.familyMemberApotheekId}
              onChange={handleOnChange}
              label="Apotheek"
            >
              <MenuItem value="">
                <em>Geen apotheek geselecteerd</em>
              </MenuItem>
              {serviceProviders.apotheken.map((apotheek) => (
                <MenuItem key={apotheek.id} value={apotheek.id}>
                  {apotheek.apotheekNaam}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth>
            <InputLabel>Verloskundige</InputLabel>
            <Select
              name="familyMemberVerloskundigeId"
              value={data.familyMemberVerloskundigeId}
              onChange={handleOnChange}
              label="Verloskundige"
            >
              <MenuItem value="">
                <em>Geen verloskundige geselecteerd</em>
              </MenuItem>
              {serviceProviders.verloskundigen.map((verloskundige) => (
                <MenuItem key={verloskundige.id} value={verloskundige.id}>
                  {verloskundige.verloskundigePraktijk}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <Button variant="text" color="primary" onClick={handleSubmit}>
            Opslaan
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}

export default NewFamilyMember;
