import React, { useEffect, useState, useReducer } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import axios from "axios";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

function NewFamily() {
  const navigate = useNavigate();
  const today = dayjs();
  const [users, setUsers] = useState(null);
  const [data, setData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      familyName: "",
      familyStreet: "",
      familyHousenumber: "",
      familyPostalcode: "",
      familyCity: "",
      familyPhone: "",
      familyCellPhone: "",
      familyEmail: "",
      familyDateInflo: today,
      familyVolunteer: "",
    }
  );

  const { instance, accounts } = useMsal();

  const GetAccessTokens = async () => {
    const request = {
      scopes: ["api://e8ee6ebf-41ff-4a7a-8d4b-2b2b4aa05d8e/User.Read"],
      account: accounts[0],
    };
    console.log(request);
    try {
      const response = await instance.acquireTokenSilent(request);
      console.log(response);
      return response.accessToken;
    } catch (e) {
      if (e instanceof InteractionRequiredAuthError) {
        return instance.acquireTokenRedirect(request);
      }
    }
  };
  const getAccessTokens = GetAccessTokens();

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setData({ [name]: value });
    console.log(data);
  };

  const handleClick = async () => {
    handleDateformat(data);
    axios({
      method: "POST",
      url: "/api/addfamily",
      data: {
        familyName: data.familyName,
        familyStreet: data.familyStreet,
        familyHousenumber: data.familyHousenumber,
        familyPostalcode: data.familyPostalcode,
        familyCity: data.familyCity,
        familyPhone: data.familyPhone,
        familyCellPhone: data.familyCellPhone,
        familyEmail: data.familyEmail,
        familyDateInflo: data.familyDateInflo,
        familyVolunteer: data.familyVolunteer,
      },
      headers: {
        Authorization: "Bearer " + (await getAccessTokens),
      },
    })
      .then((response) => {
        console.log(response);
        navigate("/families");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getUserdata = async () => {
    const token = await getAccessTokens;
    axios({
      method: "GET",
      url: "/api/onderhoud/userlist",
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        console.log(response);
        setUsers(response.data.users);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDateformat = (data) => {
    data.familyDateInflo = dayjs(data.familyDateInflo).format("YYYY-MM-DD");
  };

  useEffect(() => {
    getUserdata();
  }, []);

  return (
    <Box
      sx={{
        minHeight: "100vh",
        backgroundColor: "#f5f5f5",
        mt: 10,
        py: 4,
        px: { xs: 2, sm: 4, md: 6 },
      }}
    >
      <Paper
        elevation={3}
        sx={{
          maxWidth: 1000,
          mx: "auto",
          p: { xs: 2, sm: 3, md: 4 },
          borderRadius: 2,
          backgroundColor: "#ffffff",
        }}
      >
        <Box sx={{ mb: 4, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Box>
            <Typography variant="h4" color="primary" gutterBottom sx={{ fontWeight: 600 }}>
              Nieuwe Familie
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
              Voeg een nieuwe familie toe
            </Typography>
          </Box>
          <Button
            variant="outlined"
            onClick={() => navigate(-1)}
            sx={{
              borderRadius: 2,
              px: 3,
              py: 1,
            }}
          >
            Terug
          </Button>
        </Box>

        <Grid container spacing={3}>
          {/* Personal Information */}
          <Grid item xs={12}>
            <Typography variant="h6" color="primary" sx={{ mb: 2, fontWeight: 500 }}>
              Persoonlijke Informatie
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  id="familyName"
                  name="familyName"
                  label="Familienaam"
                  variant="outlined"
                  value={data.familyName}
                  onChange={handleOnChange}
                />
              </Grid>
            </Grid>
          </Grid>

          {/* Address Information */}
          <Grid item xs={12}>
            <Typography variant="h6" color="primary" sx={{ mb: 2, mt: 2, fontWeight: 500 }}>
              Adresgegevens
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={8}>
                <TextField
                  fullWidth
                  required
                  id="familyStreet"
                  name="familyStreet"
                  label="Straatnaam"
                  variant="outlined"
                  value={data.familyStreet}
                  onChange={handleOnChange}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  required
                  id="familyHousenumber"
                  name="familyHousenumber"
                  label="Huisnummer"
                  variant="outlined"
                  value={data.familyHousenumber}
                  onChange={handleOnChange}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  required
                  id="familyPostalcode"
                  name="familyPostalcode"
                  label="Postcode"
                  variant="outlined"
                  value={data.familyPostalcode}
                  onChange={handleOnChange}
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <TextField
                  fullWidth
                  required
                  id="familyCity"
                  name="familyCity"
                  label="Woonplaats"
                  variant="outlined"
                  value={data.familyCity}
                  onChange={handleOnChange}
                />
              </Grid>
            </Grid>
          </Grid>

          {/* Contact Information */}
          <Grid item xs={12}>
            <Typography variant="h6" color="primary" sx={{ mb: 2, mt: 2, fontWeight: 500 }}>
              Contactgegevens
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="familyPhone"
                  name="familyPhone"
                  label="Telefoonnummer (vast)"
                  variant="outlined"
                  value={data.familyPhone}
                  onChange={handleOnChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  
                  id="familyCellPhone"
                  name="familyCellPhone"
                  label="Mobiel nummer"
                  variant="outlined"
                  value={data.familyCellPhone}
                  onChange={handleOnChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="familyEmail"
                  name="familyEmail"
                  label="E-mailadres"
                  variant="outlined"
                  value={data.familyEmail}
                  onChange={handleOnChange}
                />
              </Grid>
            </Grid>
          </Grid>

          {/* Additional Information */}
          <Grid item xs={12}>
            <Typography variant="h6" color="primary" sx={{ mb: 2, mt: 2, fontWeight: 500 }}>
              Aanvullende Informatie
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Datum instroom"
                    value={data.familyDateInflo}
                    onChange={(date) =>
                      handleOnChange({
                        target: { value: date, name: "familyDateInflo" },
                      })
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        required
                        variant="outlined"
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth required variant="outlined">
                  <InputLabel>Maatschappelijk begeleider</InputLabel>
                  <Select
                    id="familyVolunteer"
                    name="familyVolunteer"
                    value={data.familyVolunteer}
                    onChange={handleOnChange}
                    label="Maatschappelijk begeleider"
                  >
                    {users &&
                      users.map((user) => (
                        <MenuItem key={user.id} value={user.id}>
                          {user.first_name} {user.last_name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Box sx={{ mt: 4, display: "flex", justifyContent: "flex-end", gap: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClick}
            sx={{
              px: 4,
              py: 1.5,
              borderRadius: 2,
              boxShadow: 2,
              "&:hover": {
                boxShadow: 4,
                transform: "translateY(-2px)",
              },
              transition: "all 0.2s ease-in-out",
            }}
          >
            Familie Toevoegen
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}

export default NewFamily;
