import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Spinner from "../components/Spinner";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Alert, Grid, Container, Tooltip } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { Snackbar } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PersonIcon from '@mui/icons-material/Person';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import AddIcon from '@mui/icons-material/Add';

function FamilyMemberChecklist() {
  const navigate = useNavigate();
  const location = useLocation();
  const { from } = location.state;

  const { instance, accounts } = useMsal();

  const [filterValue, setFilterValue] = useState("Plaatsing");

  const GetAccessTokens = async () => {
    const request = {
      scopes: ["api://e8ee6ebf-41ff-4a7a-8d4b-2b2b4aa05d8e/User.Read"],
      account: accounts[0],
    };
    console.log(request);
    try {
      const response = await instance.acquireTokenSilent(request);
      console.log(response);
      return response.accessToken;
    } catch (e) {
      if (e instanceof InteractionRequiredAuthError) {
        return instance.acquireTokenRedirect(request);
      }
    }
  };
  const getAccessTokens = GetAccessTokens();

  //get checklist standard from model
  const [checklistStandard, setChecklistStandard] = useState([]);
  const [checklistCats, setChecklistCats] = useState([]);
  const statusses = [
    "Niet gestart",
    "Bezig",
    "Afgerond",
    "Niet van toepassing",
  ];

  const fetchMasterdata = async () => {
    const token = await getAccessTokens;
    console.log(token);
    axios({
      method: "GET",
      url: "/api/gettaskitems",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        setChecklistStandard(response.data.taskitems);
        setChecklistCats(response.data.taskcategories);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchMasterdata();
  }, []);

  //get personal checklist
  const [checklist, setChecklist] = useState([]);
  const [listavailable, setListAvailable] = useState(false);
  const [isloading, setIsLoading] = useState(true);

  const fetchMemberCheclist = async () => {
    const token = await getAccessTokens;

    axios({
      method: "GET",
      url: "/api/getmembercheckist/" + from.id,

      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        console.log(response.data);
        setChecklist(response.data.checklists);
        sleep(1000);
        setIsLoading(false);
        setListAvailable(true);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.data.message === "No checklist found") {
          setListAvailable(false);
          setIsLoading(false);
          console.log(error.response.data.message);
        }
      });
  };

  useEffect(() => {
    fetchMemberCheclist();
  }, []);

  const handleCreateProgram = async () => {
    const token = await getAccessTokens;
    const fe_user = accounts[0].username;
    axios({
      method: "POST",
      url: "/api/addmemberchecklist/" + from.id,
      data: {
        fe_user: fe_user,
      },
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        console.log(response);
        setListAvailable(true);
        fetchMemberCheclist();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function sleep(milliseconds) {
    const date = Date.now();
    let currentDate = null;
    do {
      currentDate = Date.now();
    } while (currentDate - date < milliseconds);
  }

  const updateHandleOnChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    const updatedChecklist = checklist.map((item) => {
      switch (value) {
        case "Niet gestart":
          if (item.id === name) {
            return { ...item, color: "#f29191", tasklistStatus: value };
          }
        case "Bezig":
          if (item.id === name) {
            return { ...item, color: "#f0e68c", tasklistStatus: value };
          }
        case "Afgerond":
          if (item.id === name) {
            return { ...item, color: "#90ee90", tasklistStatus: value };
          }
        case "Niet van toepassing":
          if (item.id === name) {
            return { ...item, color: "#d3d3d3", tasklistStatus: value };
          }
          break;
      }

      return item;
    });
    setChecklist(updatedChecklist);
    saveChanges({ props: { name, value } });
  };

  const saveChanges = async ({ props }) => {
    const token = await getAccessTokens;
    axios({
      method: "POST",
      url: "/api/savememberchecklist/" + from.id,
      data: {
        id: props.name,
        tasklistStatus: props.value,
        user: accounts[0].username,
      },
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleStepBack = () => {
    navigate(-1);
  };
  const [open, setOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleNotImplemented = () => {
    setOpen(true);
  };

  const handleToEditForm = () => {
    navigate("/familycard/editfamilymember", { state: { from: from } });
  };

  const handleGoToAccounts = () => {
    navigate("/familymemberaccounts", { state: { from: from } });
  };

  const [activeTab, setActiveTab] = useState(1);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const getTasksByCategory = (categoryId) => {
    return checklist.filter(item => item.taskcategory_id === categoryId);
  };

  const getCategoryName = (categoryId) => {
    const category = checklistCats.find(cat => cat.id === categoryId);
    return category ? category.categoryname : '';
  };

  const getCategoryDescription = (categoryId) => {
    const category = checklistCats.find(cat => cat.id === categoryId);
    return category ? category.categorydescription : '';
  };

  return (
    <>
      {isloading ? (
        <Spinner message={"Checklist ophalen..."} />
      ) : (
        <>
          <Container maxWidth="lg" sx={{ mt: 10 }}>
            <Typography variant="h4" color="initial">
              Werkprogramma voor familielid {from.familyMemberFirstName}
            </Typography>

            {listavailable ? (
              <>
                <Container maxWidth="xl" sx={{ py: { xs: 2, sm: 3, md: 4 } }}>
                  <Box sx={{ mb: { xs: 2, sm: 3, md: 4 } }}>
                    <Typography 
                      variant="body1" 
                      sx={{
                        fontSize: { xs: '0.875rem', sm: '1rem' },
                        mb: 2
                      }}
                    >
                      Door middel van het aanpassen van een status wordt de
                      verandering direct in de database opgeslagen.
                      Door de status naar niet van toepassing te zetten, wordt het
                      item niet meer getoond in de lijst, de taak blijf opgeslagen
                      in de database.
                    </Typography>
                  </Box>

                  <Grid container spacing={{ xs: 1, sm: 2, md: 3 }} sx={{ mb: 4 }}>
                    <Grid item xs={12} sm={6} md={3}>
                      <Paper
                        elevation={2}
                        sx={{
                          p: 2,
                          height: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          transition: 'transform 0.2s, box-shadow 0.2s',
                          '&:hover': {
                            transform: 'translateY(-4px)',
                            boxShadow: 4,
                          },
                        }}
                      >
                        <Tooltip title="Terug naar familielijst">
                          <IconButton
                            onClick={handleStepBack}
                            sx={{
                              mb: 1,
                              p: 2,
                              color: 'primary.main',
                              bgcolor: 'primary.lighter',
                              '&:hover': {
                                bgcolor: 'primary.light',
                              },
                            }}
                          >
                            <ArrowBackIcon fontSize="large" />
                          </IconButton>
                        </Tooltip>
                        <Typography variant="body1" align="center">
                          Terug naar familielijst
                        </Typography>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Paper
                        elevation={2}
                        sx={{
                          p: 2,
                          height: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          transition: 'transform 0.2s, box-shadow 0.2s',
                          '&:hover': {
                            transform: 'translateY(-4px)',
                            boxShadow: 4,
                          },
                        }}
                      >
                        <Tooltip title="Update persoonsgegevens">
                          <IconButton
                            onClick={handleToEditForm}
                            sx={{
                              mb: 1,
                              p: 2,
                              color: 'info.main',
                              bgcolor: 'info.lighter',
                              '&:hover': {
                                bgcolor: 'info.light',
                              },
                            }}
                          >
                            <PersonIcon fontSize="large" />
                          </IconButton>
                        </Tooltip>
                        <Typography variant="body1" align="center">
                          Update persoonsgegevens
                        </Typography>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Paper
                        elevation={2}
                        sx={{
                          p: 2,
                          height: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          transition: 'transform 0.2s, box-shadow 0.2s',
                          '&:hover': {
                            transform: 'translateY(-4px)',
                            boxShadow: 4,
                          },
                        }}
                      >
                        <Tooltip title="Persoonlijke accounts">
                          <IconButton
                            onClick={handleGoToAccounts}
                            sx={{
                              mb: 1,
                              p: 2,
                              color: 'success.main',
                              bgcolor: 'success.lighter',
                              '&:hover': {
                                bgcolor: 'success.light',
                              },
                            }}
                          >
                            <AccountCircleIcon fontSize="large" />
                          </IconButton>
                        </Tooltip>
                        <Typography variant="body1" align="center">
                          Persoonlijke accounts
                        </Typography>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Paper
                        elevation={2}
                        sx={{
                          p: 2,
                          height: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          transition: 'transform 0.2s, box-shadow 0.2s',
                          '&:hover': {
                            transform: 'translateY(-4px)',
                            boxShadow: 4,
                          },
                        }}
                      >
                        <Tooltip title="Zorg en meer...">
                          <IconButton
                            onClick={handleNotImplemented}
                            sx={{
                              mb: 1,
                              p: 2,
                              color: 'warning.main',
                              bgcolor: 'warning.lighter',
                              '&:hover': {
                                bgcolor: 'warning.light',
                              },
                            }}
                          >
                            <LocalHospitalIcon fontSize="large" />
                          </IconButton>
                        </Tooltip>
                        <Typography variant="body1" align="center">
                          Zorg en meer...
                        </Typography>
                      </Paper>
                    </Grid>
                  </Grid>

                  <Box sx={{ width: '100%', mb: 4 }}>
                    <Tabs
                      value={activeTab}
                      onChange={handleTabChange}
                      variant="fullWidth"
                      aria-label="checklist categories"
                      sx={{
                        borderBottom: 1,
                        borderColor: 'divider',
                        mb: 3
                      }}
                    >
                      <Tab 
                        label="Plaatsing" 
                        value={1}
                        sx={{
                          fontSize: { xs: '0.875rem', sm: '1rem' },
                          textTransform: 'none'
                        }}
                      />
                      <Tab 
                        label="Woonzaken" 
                        value={2}
                        sx={{
                          fontSize: { xs: '0.875rem', sm: '1rem' },
                          textTransform: 'none'
                        }}
                      />
                      <Tab 
                        label="Vervolg" 
                        value={3}
                        sx={{
                          fontSize: { xs: '0.875rem', sm: '1rem' },
                          textTransform: 'none'
                        }}
                      />
                    </Tabs>

                    <Box sx={{ mb: 3 }}>
                      <Typography 
                        variant="h5" 
                        sx={{ 
                          mb: 1,
                          fontSize: { xs: '1.25rem', sm: '1.5rem' }
                        }}
                      >
                        {getCategoryName(activeTab)}
                      </Typography>
                      <Typography 
                        variant="body1" 
                        color="text.secondary"
                        sx={{
                          fontSize: { xs: '0.875rem', sm: '1rem' },
                          mb: 3
                        }}
                      >
                        {getCategoryDescription(activeTab)}
                      </Typography>
                    </Box>

                    <Grid container spacing={{ xs: 1, sm: 2, md: 3 }}>
                      {getTasksByCategory(activeTab).map((item) => (
                        <Grid item xs={12} sm={6} md={4} key={item.id}>
                          <Paper
                            elevation={2}
                            sx={{
                              p: { xs: 2, sm: 3 },
                              height: '100%',
                              borderRadius: { xs: 1, sm: 2 },
                              transition: 'all 0.3s ease-in-out',
                              '&:hover': {
                                transform: 'translateY(-2px)',
                                boxShadow: 4,
                              },
                            }}
                          >
                            <Box sx={{ mb: 2 }}>
                              <Typography 
                                variant="h6" 
                                sx={{ 
                                  fontSize: { xs: '1rem', sm: '1.1rem' },
                                  fontWeight: 500,
                                  mb: 0.5,
                                  lineHeight: 1.3,
                                  color: 'text.primary'
                                }}
                              >
                                {item.taskname}
                              </Typography>
                              {item.taskdescription && (
                                <Typography 
                                  variant="body2" 
                                  color="text.primary"
                                  sx={{ 
                                    fontSize: { xs: '0.75rem', sm: '0.875rem' },
                                    mb: 2,
                                    opacity: 0.9
                                  }}
                                >
                                  {item.taskdescription}
                                </Typography>
                              )}
                            </Box>
                            <FormControl 
                              fullWidth 
                              variant="outlined"
                              sx={{
                                '& .MuiOutlinedInput-root': {
                                  borderRadius: 2,
                                  transition: 'all 0.2s ease-in-out',
                                  '&:hover': {
                                    '& .MuiOutlinedInput-notchedOutline': {
                                      borderWidth: '2px',
                                    },
                                  },
                                  '&.Mui-focused': {
                                    '& .MuiOutlinedInput-notchedOutline': {
                                      borderWidth: '2px',
                                    },
                                  },
                                },
                              }}
                            >
                              <Select
                                id={item.id}
                                name={item.id}
                                value={item.tasklistStatus}
                                onChange={updateHandleOnChange}
                                sx={{
                                  backgroundColor: item.color,
                                  '& .MuiSelect-select': {
                                    fontSize: { xs: '0.875rem', sm: '1rem' },
                                    py: 1.5,
                                    fontWeight: 500,
                                    color: 'text.primary',
                                  },
                                  '& .MuiSvgIcon-root': {
                                    fontSize: '1.5rem',
                                    color: 'text.primary',
                                  },
                                }}
                                MenuProps={{
                                  PaperProps: {
                                    sx: {
                                      borderRadius: 2,
                                      mt: 1,
                                      boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                                      '& .MuiMenuItem-root': {
                                        py: 1.5,
                                        px: 2,
                                        fontSize: { xs: '0.875rem', sm: '1rem' },
                                        fontWeight: 400,
                                        color: 'text.primary',
                                        transition: 'all 0.1s ease-in-out',
                                        '&:hover': {
                                          backgroundColor: 'action.hover',
                                        },
                                        '&.Mui-selected': {
                                          fontWeight: 500,
                                          backgroundColor: 'action.selected',
                                          '&:hover': {
                                            backgroundColor: 'action.selected',
                                          },
                                        },
                                      },
                                    },
                                  },
                                }}
                              >
                                {statusses.map((status) => (
                                  <MenuItem 
                                    key={status} 
                                    value={status}
                                  >
                                    {status}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                              <Typography 
                                variant="caption" 
                                sx={{ 
                                  fontSize: { xs: '0.7rem', sm: '0.75rem' },
                                  color: 'text.primary',
                                  opacity: 0.8,
                                  fontStyle: 'italic'
                                }}
                              >
                                Laatste update: {item.tasklistUpdatedAt}
                              </Typography>
                              {item.tasklistStatus === 'Afgerond' && (
                                <CheckIcon 
                                  sx={{ 
                                    color: 'success.main',
                                    fontSize: '1.25rem'
                                  }} 
                                />
                              )}
                            </Box>
                          </Paper>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </Container>
              </>
            ) : (
              <Box sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center',
                gap: 2,
                mt: 4 
              }}>
                <Typography variant="h6" color="text.secondary">
                  Werkprogramma is niet beschikbaar
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleCreateProgram}
                  startIcon={<AddIcon />}
                  sx={{
                    py: 1.5,
                    px: 3,
                    borderRadius: 2,
                    textTransform: 'none',
                    fontSize: '1rem',
                  }}
                >
                  Werkprogramma aanmaken
                </Button>
              </Box>
            )}
          </Container>
        </>
      )}
    </>
  );
}

export default FamilyMemberChecklist;
