import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import axios from "axios";
import { useAuth } from "../authentication/useAuth";

function Users() {
	const { getAccessToken } = useAuth();
  	const token = getAccessToken();
	const [rows, setRows] = useState([]);
	const [currentUserRole, setCurrentUserRole] = useState(null);
	const [openNewDialog, setOpenNewDialog] = useState(false);
	const [openEditDialog, setOpenEditDialog] = useState(false);
	const [selectedUser, setSelectedUser] = useState(null);
	const [snackbar, setSnackbar] = useState({
		open: false,
		message: "",
		severity: "success",
	});
	const [formData, setFormData] = useState({
		first_name: "",
		last_name: "",
		email: "",
		role_id: "",
	});

	const fetchUsers = async () => {
		const getToken = await token;
		try {
			const response = await axios({
				method: "GET",
				url: "/api/onderhoud/userlist",
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + getToken,
				},
			});
			console.log(response);
			setRows(response.data.users);
		} catch (error) {
			console.error("Error fetching users:", error);
			showSnackbar("Fout bij het ophalen van gebruikers", "error");
		}
	};

	const fetchCurrentUserRole = async () => {
		const getToken = await token;
		try {
			const response = await axios({
				method: "GET",
				url: "/api/user/current",
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + getToken,
				},
			});
			setCurrentUserRole(response.data.role_id);
		} catch (error) {
			console.error("Error fetching current user role:", error);
		}
	};

	const canAssignRole = (roleId) => {
		// Only users with Systeembeheerder role (8) can assign Systeembeheerder and Coordinator roles
		if ([1, 8].includes(roleId)) {
			return currentUserRole === 8;
		}
		return true;
	};

	useEffect(() => {
		fetchUsers();
		fetchCurrentUserRole();
	}, []);

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormData((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	const handleCreateUser = async () => {
		const getToken = await token;
		try {
			await axios({
				method: "POST",
				url: "/api/onderhoud/user/create",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${getToken}`,
				},
				data: formData,
			});
			setOpenNewDialog(false);
			showSnackbar("Gebruiker succesvol aangemaakt", "success");
			fetchUsers();
			resetForm();
		} catch (error) {
			console.error("Error creating user:", error);
			showSnackbar("Fout bij het aanmaken van gebruiker", "error");
		}
	};

	const handleUpdateUser = async () => {
		const getToken = await token;
		try {
			await axios({
				method: "PUT",
				url: `/api/onderhoud/user/${selectedUser.id}`,
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${getToken}`,
				},
				data: formData,
			});
			setOpenEditDialog(false);
			showSnackbar("Gebruiker succesvol bijgewerkt", "success");
			fetchUsers();
			resetForm();
		} catch (error) {
			console.error("Error updating user:", error);
			showSnackbar("Fout bij het bijwerken van gebruiker", "error");
		}
	};

	const handleEditClick = async (userId) => {
		try {
			const getToken = await token;
			const response = await axios({
				method: "GET",
				url: `/api/onderhoud/user/${userId}`,
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${getToken}`,
				},
			});
			setSelectedUser(response.data.user);
			setFormData(response.data.user);
			setOpenEditDialog(true);
		} catch (error) {
			console.error("Error fetching user details:", error);
			showSnackbar("Fout bij het ophalen van gebruikersgegevens", "error");
		}
	};

	const resetForm = () => {
		setFormData({
			first_name: "",
			last_name: "",
			email: "",
			role_id: "",
		});
		setSelectedUser(null);
	};

	const showSnackbar = (message, severity) => {
		setSnackbar({
			open: true,
			message,
			severity,
		});
	};

	const handleCloseSnackbar = () => {
		setSnackbar((prev) => ({
			...prev,
			open: false,
		}));
	};

	return (
		<>
			<Container maxWidth="lg" sx={{ py: 4, mt: 10, ml: 10 }}>
				<Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
					<Button
						variant="contained"
						startIcon={<AddIcon />}
						onClick={() => setOpenNewDialog(true)}
					>
						Nieuwe Gebruiker
					</Button>
				</Box>
				<TableContainer component={Paper}>
					<Table sx={{ minWidth: 650 }} aria-label="users table">
						<TableHead>
							<TableRow>
								<TableCell>UserID</TableCell>
								<TableCell>Voornaam</TableCell>
								<TableCell>Achternaam</TableCell>
								<TableCell>Gebruikersrol</TableCell>
								<TableCell>Type gebruikersrol</TableCell>
								<TableCell align="right">Acties</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{rows.map((row) => (
								<TableRow
									key={row.id}
									sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
								>
									<TableCell>{row.id}</TableCell>
									<TableCell>{row.first_name}</TableCell>
									<TableCell>{row.last_name}</TableCell>
									<TableCell>{row.rolename}</TableCell>
									<TableCell>{row.rolesubtype}</TableCell>
									<TableCell align="right">
										<Button
											startIcon={<EditIcon />}
											onClick={() => handleEditClick(row.id)}
										>
											Bewerken
										</Button>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>

				{/* New User Dialog */}
				<Dialog open={openNewDialog} onClose={() => setOpenNewDialog(false)} maxWidth="sm" fullWidth>
					<DialogTitle>Nieuwe Gebruiker Toevoegen</DialogTitle>
					<DialogContent>
						<Box sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 2 }}>
							<TextField
								fullWidth
								label="Voornaam"
								name="first_name"
								value={formData.first_name}
								onChange={handleInputChange}
							/>
							<TextField
								fullWidth
								label="Achternaam"
								name="last_name"
								value={formData.last_name}
								onChange={handleInputChange}
							/>
							<TextField
								fullWidth
								label="Email"
								name="email"
								value={formData.email}
								onChange={handleInputChange}
							/>
							<FormControl fullWidth>
								<InputLabel>Gebruikersrol</InputLabel>
								<Select
									name="role_id"
									value={formData.role_id}
									onChange={handleInputChange}
									label="Gebruikersrol"
								>
									<MenuItem disabled style={{opacity: 0.6}}>Bureaumedewerkers</MenuItem>
									{canAssignRole(1) && <MenuItem value={1}>Coordinator</MenuItem>}
									<MenuItem value={2}>Assistent Coordinator</MenuItem>
									<MenuItem value={3}>Administratief ondersteuner</MenuItem>
									<MenuItem disabled style={{opacity: 0.6}}>Vrijwilligers</MenuItem>
									<MenuItem value={4}>Woonbegeleider</MenuItem>
									<MenuItem value={5}>Maatschappelijk begeleider</MenuItem>
									<MenuItem value={6}>Administratief begeleider</MenuItem>
									<MenuItem disabled style={{opacity: 0.6}}>Bestuur</MenuItem>
									<MenuItem value={7}>Penningmeester</MenuItem>
									<MenuItem disabled style={{opacity: 0.6}}>Beheerder</MenuItem>
									{canAssignRole(8) && <MenuItem value={8}>Systeembeheerder</MenuItem>}
								</Select>
							</FormControl>
						</Box>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => setOpenNewDialog(false)}>Annuleren</Button>
						<Button onClick={handleCreateUser} variant="contained">
							Toevoegen
						</Button>
					</DialogActions>
				</Dialog>

				{/* Edit User Dialog */}
				<Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)} maxWidth="sm" fullWidth>
					<DialogTitle>Gebruiker Bewerken</DialogTitle>
					<DialogContent>
						<Box sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 2 }}>
							<TextField
								fullWidth
								label="Voornaam"
								name="first_name"
								value={formData.first_name}
								onChange={handleInputChange}
							/>
							<TextField
								fullWidth
								label="Achternaam"
								name="last_name"
								value={formData.last_name}
								onChange={handleInputChange}
							/>
							<TextField
								fullWidth
								label="Email"
								name="email"
								value={formData.email}
								onChange={handleInputChange}
							/>
							<FormControl fullWidth>
								<InputLabel>Gebruikersrol</InputLabel>
								<Select
									name="role_id"
									value={formData.role_id}
									onChange={handleInputChange}
									label="Gebruikersrol"
								>
									<MenuItem disabled style={{opacity: 0.6}}>Bureaumedewerkers</MenuItem>
									{canAssignRole(1) && <MenuItem value={1}>Coordinator</MenuItem>}
									<MenuItem value={2}>Assistent Coordinator</MenuItem>
									<MenuItem value={3}>Administratief ondersteuner</MenuItem>
									<MenuItem disabled style={{opacity: 0.6}}>Vrijwilligers</MenuItem>
									<MenuItem value={4}>Woonbegeleider</MenuItem>
									<MenuItem value={5}>Maatschappelijk begeleider</MenuItem>
									<MenuItem value={6}>Administratief begeleider</MenuItem>
									<MenuItem disabled style={{opacity: 0.6}}>Bestuur</MenuItem>
									<MenuItem value={7}>Penningmeester</MenuItem>
									<MenuItem disabled style={{opacity: 0.6}}>Beheerder</MenuItem>
									{canAssignRole(8) && <MenuItem value={8}>Systeembeheerder</MenuItem>}
								</Select>
							</FormControl>
						</Box>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => setOpenEditDialog(false)}>Annuleren</Button>
						<Button onClick={handleUpdateUser} variant="contained">
							Opslaan
						</Button>
					</DialogActions>
				</Dialog>

				{/* Snackbar for notifications */}
				<Snackbar
					open={snackbar.open}
					autoHideDuration={6000}
					onClose={handleCloseSnackbar}
					anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
				>
					<Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
						{snackbar.message}
					</Alert>
				</Snackbar>
			</Container>
		</>
	);
}

export default Users;
