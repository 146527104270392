import React from "react";
import Typography from "@mui/material/Typography";
import { Box, Container } from "@mui/material";

function NotFound() {
  return (
    <div>
      {" "}
      <Container>
        <Box>
          <Typography variant="h4" color="initial" sx={{ marginTop: 10 }}>
            404 - Not Found
          </Typography>
        </Box>
      </Container>
    </div>
  );
}

export default NotFound;
