import React, { useReducer, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import Typography from "@mui/material/Typography";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Button,
  Paper,
  Box,
  CircularProgress,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import axios from "axios";
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";

dayjs.extend(utc);
dayjs.extend(timezone);

function EditFamilyMember() {
  const location = useLocation();
  const { from } = location.state;
  const navigate = useNavigate();
  console.log(from);

  const { instance, accounts } = useMsal();

  const GetAccessTokens = async () => {
    const request = {
      scopes: ["api://e8ee6ebf-41ff-4a7a-8d4b-2b2b4aa05d8e/User.Read"],
      account: accounts[0],
    };
    console.log(request);
    try {
      const response = await instance.acquireTokenSilent(request);
      console.log(response);
      return response.accessToken;
    } catch (e) {
      if (e instanceof InteractionRequiredAuthError) {
        return instance.acquireTokenRedirect(request);
      }
    }
  };
  const getAccessTokens = GetAccessTokens();

  // Service providers state
  const [serviceProviders, setServiceProviders] = useState({
    tandartsen: [],
    huisartsen: [],
    verloskundigen: [],
    apotheken: []
  });

  // Add service provider IDs to the data state
  const [data, setData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      familyId: from.id,
      familyMemberFirstName: "",
      familyMemberSurname: "",
      familyMemberSocialNumber: "",
      familyMemberBankAccount: "",
      familyMemberEmail: "",
      familyMemberPhone: "",
      familyMemberGender: "",
      familyMemberMotherTongue: "",
      familyMemberNationality: "",
      familyEmail: "",
      familyVolunteer: "",
      familyMemberBirthdate: dayjs(),
      plaatsingDate: dayjs(),
      datumNederland: dayjs(),
      datumWbD: dayjs(),
      datumUitstroom: dayjs(),
      // Service provider selections
      familyMemberHuisartsId: "",
      familyMemberTandartsId: "",
      familyMemberApotheekId: "",
      familyMemberVerloskundigeId: "",
    }
  );

  // Method for updating the data state based on the several fetches below
  const updateDataState = (responseData) => {
    if (!responseData) return;
    
    // If responseData is an array, handle each item
    if (Array.isArray(responseData)) {
      responseData.forEach(item => {
        if (item && item.name && item.value !== undefined) {
          // Convert date strings to DayJS objects
          const value = item.name.includes('Date') || item.name.includes('datum')
            ? dayjs(item.value)
            : item.value;
          setData({ [item.name]: value });
        }
      });
    } 
    // If responseData is an object, convert dates and update all properties at once
    else if (typeof responseData === 'object') {
      const processedData = Object.entries(responseData).reduce((acc, [key, value]) => {
        // Convert date strings to DayJS objects for date fields
        if (key.includes('Date') || key.includes('datum')) {
          acc[key] = value ? dayjs(value) : dayjs();
        } else {
          acc[key] = value;
        }
        return acc;
      }, {});
      setData(processedData);
    }
  }

  // Fetch familymember data from database
  const getMemberData = async () => {
    const token = await getAccessTokens;
    const userEmail = accounts[0]?.username;

    try {
      const response = await axios({
        method: "GET",
        url: "/api/familymember/" + from.id,
        headers: {
          Authorization: `Bearer ${token}`,
          "X-User-Email": userEmail,
        },
      });
      if (response.data && response.data.family_member) {
        const memberData = response.data.family_member;
        // Ensure dates are converted to DayJS objects
        const processedData = {
          ...memberData,
          familyMemberBirthdate: dayjs(memberData.familyMemberBirthdate || new Date()),
        };
        updateDataState(processedData);
      }
    } catch (error) {
      console.error('Error fetching member data:', error);
    }
  }

  // Fetch familymember metadata from database
  const getMemberMetadata = async () => {
    const token = await getAccessTokens;
    const userEmail = accounts[0]?.username;

    try {
      const response = await axios({
        method: "GET",
        url: "/api/getmembermetadata/" + from.id,
        headers: {
          Authorization: `Bearer ${token}`,
          "X-User-Email": userEmail,
        },
      });
      if (response.data) {
        const metadata = {
          familyMemberHuisartsId: response.data.huisarts_id || "",
          familyMemberTandartsId: response.data.tandarts_id || "",
          familyMemberApotheekId: response.data.apotheek_id || "",
          familyMemberVerloskundigeId: response.data.verloskundige_id || ""
        };
        updateDataState(metadata);
      }
    } catch (error) {
      console.error('Error fetching metadata:', error);
    }
  }

  // Fetch familymember FamilyMemberPlaatsing data from database
  const getFamilyMemberPlaatsing = async () => {
    const token = await getAccessTokens;
    const userEmail = accounts[0]?.username;

    try {
      const response = await axios({
        method: "GET",
        url: "/api/familymemberplaatsing/" + from.id,
        headers: {
          Authorization: `Bearer ${token}`,
          "X-User-Email": userEmail,
        },
      });
      if (response.data && response.data.familyMemberPlaatsing) {
        const plaatsing = {
          plaatsingDate: dayjs(response.data.familyMemberPlaatsing.plaatsing_date || new Date()),
          datumNederland: dayjs(response.data.familyMemberPlaatsing.datum_nederland || new Date()),
          datumWbD: dayjs(response.data.familyMemberPlaatsing.datum_wbd || new Date()),
          datumUitstroom: dayjs(response.data.familyMemberPlaatsing.datum_uitstroom || new Date())
        };
        updateDataState(plaatsing);
      }
    } catch (error) {
      console.error('Error fetching plaatsing data:', error);
    }
  };

  // Fetch service providers
  const fetchServiceProviders = async () => {
    const token = await getAccessTokens;
    const userEmail = accounts[0]?.username;

    try {
      const [tandartsenRes, huisartsenRes, verloskundigenRes, apothekenRes] = await Promise.all([
        axios.get('/api/onderhoud/tandartsen', {
          headers: { 
            Authorization: `Bearer ${token}`,
            "X-User-Email": userEmail,
          }
        }),
        axios.get('/api/onderhoud/huisartsen', {
          headers: { 
            Authorization: `Bearer ${token}`,
            "X-User-Email": userEmail,
          }
        }),
        axios.get('/api/onderhoud/verloskundigen', {
          headers: { 
            Authorization: `Bearer ${token}`,
            "X-User-Email": userEmail,
          }
        }),
        axios.get('/api/onderhoud/apotheken', {
          headers: { 
            Authorization: `Bearer ${token}`,
            "X-User-Email": userEmail,
          }
        })
      ]);

      setServiceProviders({
        tandartsen: tandartsenRes.data.tandartsen || [],
        huisartsen: huisartsenRes.data.huisartsen || [],
        verloskundigen: verloskundigenRes.data.verloskundigen || [],
        apotheken: apothekenRes.data.apotheken || []
      });
    } catch (error) {
      console.error('Error fetching service providers:', error);
    }
  };

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchAllData = async () => {
      setIsLoading(true);
      try {
        await Promise.all([
          getMemberData(),
          getMemberMetadata(),
          getFamilyMemberPlaatsing(),
          fetchServiceProviders()
        ]);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAllData();
  }, []);

  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setData({ [name]: value });
    console.log(data);
  };

  const handleDateformat = (data) => {
    data.familyMemberBirthdate = dayjs(data.familyMemberBirthdate).format(
      "YYYY-MM-DD"
    );
    data.plaatsingDate = dayjs(data.plaatsingDate).format("YYYY-MM-DD");
    data.datumNederland = dayjs(data.datumNederland).format("YYYY-MM-DD");
    data.datumWbD = dayjs(data.datumWbD).format("YYYY-MM-DD");
    data.datumUitstroom = dayjs(data.datumUitstroom).format("YYYY-MM-DD");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    switch (true) {
      case validateEmail(data.familyMemberEmail) === false:
        setEmailError(true);
        break;
      case validateEmail(data.familyMemberEmail) === true:
        setEmailError(false);
        break;

      default:
    }

    if (validateEmail(data.familyMemberEmail) === true) {
      const formattedData = { ...data };
      handleDateformat(formattedData);

      const token = await getAccessTokens;
      const userEmail = accounts[0]?.username; // Get email from MSAL account

      try {
        // Update family member
        await axios({
          method: "PUT",
          url: "/api/updatefamilymember/" + from.id,
          data: formattedData,
          headers: {
            Authorization: "Bearer " + token,
            "X-User-Email": userEmail,
          },
        });

        // Update metadata
        await axios({
          method: "PUT",
          url: "/api/updatemembermetadata/" + from.id,
          data: {
            familyMemberHuisartsId: formattedData.familyMemberHuisartsId,
            familyMemberTandartsId: formattedData.familyMemberTandartsId,
            familyMemberApotheekId: formattedData.familyMemberApotheekId,
            familyMemberVerloskundigeId: formattedData.familyMemberVerloskundigeId,
          },
          headers: {
            Authorization: "Bearer " + token,
            "X-User-Email": userEmail,
          },
        });

        navigate(-1);
      } catch (error) {
        console.error("Error updating family member:", error);
      }
    }
  };

  const validateEmail = (email) => {
    const regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    console.log(regex.test(email));
    return regex.test(email);
  };

  return (
    <Container
      maxWidth="lg"
      sx={{
        py: 4,
        "& .MuiTextField-root": { width: "100%" },
        "& .MuiFormControl-root": { width: "100%" },
      }}
    >
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
          <CircularProgress />
        </Box>
      ) : (
        <Paper elevation={3} sx={{ p: 4 }}>
          <Typography variant="h4" color="primary" gutterBottom sx={{ mb: 4 }}>
            Familielidgegevens bijwerken {from.familyName}
          </Typography>
          
          <Box component="form" onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              {/* Personal Information Section */}
              <Grid item xs={12}>
                <Typography variant="h6" color="primary" sx={{ mb: 2 }}>
                  Persoonlijke Informatie
                </Typography>
              </Grid>
              
              <Grid item xs={12} md={6}>
                <TextField
                  id="familyMemberFirstName"
                  name="familyMemberFirstName"
                  label="Voornaam"
                  variant="outlined"
                  autoComplete="off"
                  onChange={handleOnChange}
                  value={data.familyMemberFirstName}
                  required
                  fullWidth
                />
              </Grid>
              
              <Grid item xs={12} md={6}>
                <TextField
                  id="familyMemberSurname"
                  name="familyMemberSurname"
                  label="Achternaam"
                  variant="outlined"
                  autoComplete="off"
                  onChange={handleOnChange}
                  value={data.familyMemberSurname}
                  required
                  fullWidth
                />
              </Grid>
              
              <Grid item xs={12} md={6}>
                <TextField
                  id="familyMemberSocialNumber"
                  name="familyMemberSocialNumber"
                  label="BSN"
                  variant="outlined"
                  autoComplete="off"
                  onChange={handleOnChange}
                  value={data.familyMemberSocialNumber}
                  required
                  fullWidth
                />
              </Grid>
              
              <Grid item xs={12} md={6}>
                <TextField
                  id="familyMemberBankAccount"
                  name="familyMemberBankAccount"
                  label="Bankrekeningnummer"
                  variant="outlined"
                  autoComplete="off"
                  onChange={handleOnChange}
                  value={data.familyMemberBankAccount}
                  fullWidth
                />
              </Grid>
              
              <Grid item xs={12} md={6}>
                <TextField
                  id="familyMemberEmail"
                  name="familyMemberEmail"
                  label="Persoonlijk emailadres"
                  variant="outlined"
                  autoComplete="off"
                  onChange={handleOnChange}
                  error={emailError}
                  helperText={emailError ? "Ongeldig emailadres" : ""}
                  value={data.familyMemberEmail}
                  required
                  fullWidth
                />
              </Grid>
              
              <Grid item xs={12} md={6}>
                <TextField
                  id="familyMemberPhone"
                  name="familyMemberPhone"
                  label="Telefoonnummer"
                  variant="outlined"
                  autoComplete="off"
                  onChange={handleOnChange}
                  value={data.familyMemberPhone}
                  fullWidth
                />
              </Grid>
              
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel id="familyMemberGender">Geslacht</InputLabel>
                  <Select
                    labelId="familyMemberGender"
                    id="familyMemberGender"
                    name="familyMemberGender"
                    label="Geslacht"
                    variant="outlined"
                    onChange={handleOnChange}
                    value={data.familyMemberGender}
                    required
                  >
                    <MenuItem value={10}>Man</MenuItem>
                    <MenuItem value={20}>Vrouw</MenuItem>
                    <MenuItem value={30}>Neutraal</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              
              <Grid item xs={12} md={6}>
                <TextField
                  id="familyMemberMotherTongue"
                  name="familyMemberMotherTongue"
                  label="Moedertaal"
                  variant="outlined"
                  autoComplete="off"
                  onChange={handleOnChange}
                  value={data.familyMemberMotherTongue}
                  required
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  id="familyMemberNationality"
                  name="familyMemberNationality"
                  label="Oorspronkelijke nationaliteit"
                  variant="outlined"
                  autoComplete="off"
                  onChange={handleOnChange}
                  value={data.familyMemberNationality}
                  required
                  fullWidth
                />
              </Grid>

              {/* Dates Section */}
              <Grid item xs={12}>
                <Typography variant="h6" color="primary" sx={{ mt: 3, mb: 2 }}>
                  Datums
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Geboortedatum"
                    value={data.familyMemberBirthdate}
                    onChange={(e) =>
                      handleOnChange({
                        target: { value: e, name: "familyMemberBirthdate" },
                      })
                    }
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" fullWidth />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12} md={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Koppelingsdatum"
                    value={data.plaatsingDate}
                    onChange={(e) =>
                      handleOnChange({
                        target: { value: e, name: "plaatsingDate" },
                      })
                    }
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" fullWidth />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12} md={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Datum in NL"
                    value={data.datumNederland}
                    onChange={(e) =>
                      handleOnChange({
                        target: { value: e, name: "datumNederland" },
                      })
                    }
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" fullWidth />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12} md={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Datum in WBD"
                    value={data.datumWbD}
                    onChange={(e) =>
                      handleOnChange({
                        target: { value: e, name: "datumWbD" },
                      })
                    }
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" fullWidth />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12} md={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Datum uitstroom"
                    value={data.datumUitstroom}
                    onChange={(e) =>
                      handleOnChange({
                        target: { value: e, name: "datumUitstroom" },
                      })
                    }
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" fullWidth />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              {/* Service Providers Section */}
              <Grid item xs={12}>
                <Typography variant="h6" color="primary" sx={{ mt: 3, mb: 2 }}>
                  Dienstverleners
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>Huisarts</InputLabel>
                  <Select
                    name="familyMemberHuisartsId"
                    value={data.familyMemberHuisartsId}
                    onChange={handleOnChange}
                    label="Huisarts"
                    variant="outlined"
                  >
                    <MenuItem value="">
                      <em>Geen huisarts geselecteerd</em>
                    </MenuItem>
                    {serviceProviders.huisartsen.map((huisarts) => (
                      <MenuItem key={huisarts.id} value={huisarts.id}>
                        {huisarts.huisartsPraktijk}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>Tandarts</InputLabel>
                  <Select
                    name="familyMemberTandartsId"
                    value={data.familyMemberTandartsId}
                    onChange={handleOnChange}
                    label="Tandarts"
                    variant="outlined"
                  >
                    <MenuItem value="">
                      <em>Geen tandarts geselecteerd</em>
                    </MenuItem>
                    {serviceProviders.tandartsen.map((tandarts) => (
                      <MenuItem key={tandarts.id} value={tandarts.id}>
                        {tandarts.tandartsPraktijk}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>Apotheek</InputLabel>
                  <Select
                    name="familyMemberApotheekId"
                    value={data.familyMemberApotheekId}
                    onChange={handleOnChange}
                    label="Apotheek"
                    variant="outlined"
                  >
                    <MenuItem value="">
                      <em>Geen apotheek geselecteerd</em>
                    </MenuItem>
                    {serviceProviders.apotheken.map((apotheek) => (
                      <MenuItem key={apotheek.id} value={apotheek.id}>
                        {apotheek.apotheekNaam}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>Verloskundige</InputLabel>
                  <Select
                    name="familyMemberVerloskundigeId"
                    value={data.familyMemberVerloskundigeId}
                    onChange={handleOnChange}
                    label="Verloskundige"
                    variant="outlined"
                  >
                    <MenuItem value="">
                      <em>Geen verloskundige geselecteerd</em>
                    </MenuItem>
                    {serviceProviders.verloskundigen.map((verloskundige) => (
                      <MenuItem key={verloskundige.id} value={verloskundige.id}>
                        {verloskundige.verloskundigePraktijk}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* Actions Section */}
              <Grid item xs={12}>
                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => navigate(-1)}
                    size="large"
                  >
                    Annuleren
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    size="large"
                  >
                    Opslaan
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      )}
    </Container>
  );
}

export default EditFamilyMember;
