import React from "react";
import { createTheme } from "@mui/material/styles";

const Theme = createTheme({
  palette: {
    primary: {
      main: "#59a2bf",
      light: "#7bb4cc",
      dark: "#3d7f99",
      contrastText: "#ffffff"
    },
    secondary: {
      main: "#bf7759",
      light: "#cc957b",
      dark: "#995c3d",
      contrastText: "#ffffff"
    },
    background: {
      default: "#f8f9fa",
      paper: "#ffffff"
    },
    text: {
      primary: "#2d3e4f",
      secondary: "#59a2bf",
      disabled: "#90a4ae"
    },
    divider: "rgba(0, 0, 0, 0.12)",
    action: {
      active: "#59a2bf",
      hover: "rgba(89, 162, 191, 0.08)",
      selected: "rgba(89, 162, 191, 0.16)",
      disabled: "rgba(0, 0, 0, 0.26)",
      disabledBackground: "rgba(0, 0, 0, 0.12)",
    }
  },
  shadows: [
    'none',
    '0px 2px 4px rgba(0,0,0,0.08)',
    '0px 4px 8px rgba(0,0,0,0.12)',
    '0px 8px 16px rgba(0,0,0,0.16)',
    '0px 16px 32px rgba(0,0,0,0.2)',
    '0px 24px 48px rgba(0,0,0,0.24)',
    '0px 32px 64px rgba(0,0,0,0.28)',
    '0px 40px 80px rgba(0,0,0,0.32)',
    '0px 48px 96px rgba(0,0,0,0.36)',
    '0px 56px 112px rgba(0,0,0,0.4)',
    '0px 64px 128px rgba(0,0,0,0.44)',
    '0px 72px 144px rgba(0,0,0,0.48)',
    '0px 80px 160px rgba(0,0,0,0.52)',
    '0px 88px 176px rgba(0,0,0,0.56)',
    '0px 96px 192px rgba(0,0,0,0.6)',
    '0px 104px 208px rgba(0,0,0,0.64)',
    '0px 112px 224px rgba(0,0,0,0.68)',
    '0px 120px 240px rgba(0,0,0,0.72)',
    '0px 128px 256px rgba(0,0,0,0.76)',
    '0px 136px 272px rgba(0,0,0,0.8)',
    '0px 144px 288px rgba(0,0,0,0.84)',
    '0px 152px 304px rgba(0,0,0,0.88)',
    '0px 160px 320px rgba(0,0,0,0.92)',
    '0px 168px 336px rgba(0,0,0,0.96)',
    '0px 176px 352px rgba(0,0,0,1)',
    '0px 184px 368px rgba(0,0,0,1)',
    '0px 192px 384px rgba(0,0,0,1)',
    '0px 200px 400px rgba(0,0,0,1)',
    '0px 208px 416px rgba(0,0,0,1)',
    '0px 216px 432px rgba(0,0,0,1)',
    '0px 224px 448px rgba(0,0,0,1)',
    '0px 232px 464px rgba(0,0,0,1)',
    '0px 240px 480px rgba(0,0,0,1)',
    '0px 248px 496px rgba(0,0,0,1)',
    '0px 256px 512px rgba(0,0,0,1)'
  ],
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          margin: 0,
          padding: 0
        }
      }
    },
    MuiContainer: {
      styleOverrides: {
        root: ({ theme }) => ({
          [theme.breakpoints.down('sm')]: {
            paddingLeft: '16px',
            paddingRight: '16px'
          },
          [theme.breakpoints.between('sm', 'md')]: {
            paddingLeft: '24px',
            paddingRight: '24px'
          },
          [theme.breakpoints.up('md')]: {
            paddingLeft: '32px',
            paddingRight: '32px'
          }
        })
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: ({ theme }) => ({
          [theme.breakpoints.down('sm')]: {
            width: '240px'
          },
          [theme.breakpoints.up('sm')]: {
            width: '280px'
          }
        })
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          boxShadow: '0px 2px 4px rgba(0,0,0,0.08)',
          '&:hover': {
            boxShadow: '0px 4px 8px rgba(0,0,0,0.12)',
          },
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
        elevation1: {
          boxShadow: '0px 2px 4px rgba(0,0,0,0.08)',
        },
        elevation2: {
          boxShadow: '0px 4px 8px rgba(0,0,0,0.12)',
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          textTransform: 'none',
          fontWeight: 600,
          boxShadow: 'none',
        },
        contained: {
          '&:hover': {
            boxShadow: '0px 2px 4px rgba(0,0,0,0.12)',
          }
        },
        outlined: {
          borderWidth: 2,
          '&:hover': {
            borderWidth: 2,
            backgroundColor: 'rgba(89, 162, 191, 0.08)',
          }
        }
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          '& .MuiTableCell-head': {
            backgroundColor: '#59a2bf',
            color: '#ffffff',
            fontWeight: 600,
          }
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:nth-of-type(odd)': {
            backgroundColor: 'rgba(0, 0, 0, 0.02)',
          },
          '&:hover': {
            backgroundColor: 'rgba(89, 162, 191, 0.08) !important',
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          margin: '4px 8px',
          '&:hover': {
            backgroundColor: 'rgba(89, 162, 191, 0.08)',
          },
          '&.Mui-selected': {
            backgroundColor: 'rgba(89, 162, 191, 0.16)',
            '&:hover': {
              backgroundColor: 'rgba(89, 162, 191, 0.24)',
            }
          }
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          fontWeight: 500,
        },
        filled: {
          backgroundColor: 'rgba(89, 162, 191, 0.12)',
          color: '#3d7f99',
          '&:hover': {
            backgroundColor: 'rgba(89, 162, 191, 0.2)',
          }
        },
        outlined: {
          borderWidth: 2,
          '&:hover': {
            backgroundColor: 'rgba(89, 162, 191, 0.08)',
          }
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: 'rgba(0, 0, 0, 0.12)',
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderWidth: 2,
              borderColor: 'rgba(0, 0, 0, 0.12)',
            },
            '&:hover fieldset': {
              borderColor: '#59a2bf',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#59a2bf',
              borderWidth: 2,
            }
          }
        }
      }
    }
  },
  typography: {
    fontFamily: "Ubuntu, sans-serif",
    h4: {
      fontWeight: 700,
      color: '#2d3e4f',
      letterSpacing: -0.5,
    },
    h5: {
      fontWeight: 600,
      color: '#3d7f99',
      letterSpacing: -0.25,
    },
    h6: {
      fontWeight: 600,
      color: '#59a2bf',
    },
    subtitle1: {
      fontWeight: 500,
      color: '#2d3e4f',
    },
    subtitle2: {
      fontWeight: 500,
      color: '#59a2bf',
    },
    body1: {
      color: '#2d3e4f',
    },
    body2: {
      color: '#3d7f99',
    },
    button: {
      fontWeight: 600,
    }
  }
});

export default Theme;
