const VersionInfo = {
  version: {
    release: "1.0.4",
    releasedate: "2024-11-25",
    frondendversion: "1.0.4",
    backendversion: "1.0.4",
    environment: "development",
    name: "vwgidsapp",
    description: "A description of your application",
    author: "Tijmen V. Baas",
    license: "MIT",
  },
};

export default VersionInfo;
