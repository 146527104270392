import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter, useNavigate } from "react-router-dom";

import { msalConfig } from "./authentication/authConfig";

import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";

import "./index.css";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

// MSAL configuration
const configuration = msalConfig;

const pca = new PublicClientApplication(configuration);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<MsalProvider instance={pca}>
				<App />
			</MsalProvider>
		</BrowserRouter>
	</React.StrictMode>
);
