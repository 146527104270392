import React, { useEffect, useState, useReducer } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import axios from "axios";
import IconButton from "@mui/material/IconButton";
// @ts-ignore
import { Link } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import UpgradeIcon from "@mui/icons-material/Upgrade";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import Diversity1OutlinedIcon from "@mui/icons-material/Diversity1Outlined";
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import Grid from "@mui/material/Grid";
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import DeleteIcon from '@mui/icons-material/Delete';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

function FamilyLogboek() {
  const location = useLocation();
  const navigate = useNavigate();
  
  // Redirect if no state is provided
  useEffect(() => {
    if (!location.state || !location.state.from) {
      navigate('/');
      return;
    }
  }, [location, navigate]);

  const { from } = location.state || {};
  const [logboek, setLogboek] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [defParamsLoaded, setDefParamsLoaded] = useState(false);
  const [categories, setCategories] = useState([]);
  const [meetingType, setMeetingType] = useState([]);
  const [members, setMembers] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  const [newItemOpen, setNewItemOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [attachedFiles, setAttachedFiles] = useState([]);
  const [fileDescription, setFileDescription] = useState("");

  const url = "/api/familylogboek/" + from.id;
  const variablesurl = "/api/logboekvariables/" + from.id;

  const { instance, accounts } = useMsal();
  const currentUser = accounts[0].username;

  const GetAccessTokens = async () => {
    const request = {
      scopes: ["api://e8ee6ebf-41ff-4a7a-8d4b-2b2b4aa05d8e/User.Read"],
      account: accounts[0],
    };
    try {
      const response = await instance.acquireTokenSilent(request);
      return response.accessToken;
    } catch (e) {
      if (e instanceof InteractionRequiredAuthError) {
        return instance.acquireTokenRedirect(request);
      }
    }
  };
  const getAccessTokens = GetAccessTokens();

  const fetchMasterData = async () => {
    const token = await getAccessTokens;
    if (!defParamsLoaded) {
      try {
        const response = await axios({
          method: "get",
          url: variablesurl,
          headers: { Authorization: "Bearer " + token },
        });
        console.log("Master data response:", response.data);
        setCategories(response.data.categories);
        setMeetingType(response.data.contactmoment);
        setMembers(response.data.familymembers);
        setDefParamsLoaded(true);
      } catch (error) {
        console.error("Error fetching master data:", error);
      }
    }
  };

  const fetchFamilieData = async () => {
    const token = await getAccessTokens;
    try {
      const response = await axios({
        method: "get",
        url: url,
        headers: { Authorization: "Bearer " + token },
      });
      setLogboek(response.data.logbookitems);
    } catch (error) {
      console.error("Error fetching family data:", error);
    }
  };

  useEffect(() => {
    fetchMasterData();
  }, []);

  useEffect(() => {
    fetchFamilieData();
  }, [refresh]);

  const [modalData, setModalData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      categoryID: "",
      categoryname: "",
      contactType: "",
      contactmomenttype: "",
      familyId: from.id,
      familyMemberFirstName: "",
      familyMemberId: "",
      familyMemberSurname: "",
      familyName: "",
      id: "",
      logboekTitle: "",
      logbookDescription: "",
      logbookDocLink: "",
      logboekDateOccurence: dayjs(),
      logboekCreatedAt: "",
    }
  );

  const resetModalData = () => {
    setModalData({
      categoryID: "",
      categoryname: "",
      contactType: "",
      contactmomenttype: "",
      familyId: from.id,
      familyMemberFirstName: "",
      familyMemberId: "",
      familyMemberSurname: "",
      familyName: "",
      id: "",
      logboekTitle: "",
      logbookDescription: "",
      logbookDocLink: "",
      logboekDateOccurence: dayjs(),
      logboekCreatedAt: "",
    });
  };

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleNewItemOpen = () => setNewItemOpen(true);
  const handleNewItemClose = () => {
    setNewItemOpen(false);
    resetModalData();
  };

  const handleNewItemSave = async (e) => {
    e.preventDefault();
    
    // Check if from exists and has an id
    if (!from?.id) {
      setSnackbarSeverity("error");
      setSnackbarMessage("Ongeldige familie ID");
      setSnackbarOpen(true);
      return;
    }

    const token = await getAccessTokens;

    try {
      const formattedDate = dayjs(modalData.logboekDateOccurence).format('YYYY-MM-DD');
      
      const response = await axios({
        method: "post",
        url: "/api/addlogbookitem",
        headers: { Authorization: "Bearer " + token },
        data: {
          logboekTitle: modalData.logboekTitle,
          logbookDescription: modalData.logbookDescription,
          logbookDocLink: modalData.logbookDocLink,
          categoryID: modalData.categoryID,
          contactType: modalData.contactType,
          familyMemberId: modalData.familyMemberId,
          logboekDateOccurence: formattedDate,
          familyId: from.id,
          userData: currentUser,
        },
      });

      if (response.status === 200) {
        if (selectedFile) {
          await handleFileUpload(response.data.logbookitem_id);
        }
        handleNewItemClose();
        setRefresh(!refresh); // Refresh the list
        setSnackbarSeverity("success");
        setSnackbarMessage("Logboek item succesvol toegevoegd");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error saving logbook item:", error);
      setSnackbarSeverity("error");
      setSnackbarMessage("Er is een fout opgetreden bij het opslaan");
      setSnackbarOpen(true);
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    resetModalData();
  };

  const handleUpdateItem = async () => {
    try {
      const formattedDate = dayjs(modalData.logboekDateOccurence).format('YYYY-MM-DD');
      
      const response = await axios({
        method: "post",
        url: "/api/updatelogbookitem/" + modalData.id,
        headers: { Authorization: "Bearer " + (await getAccessTokens) },
        data: {
          logboekTitle: modalData.logboekTitle,
          logbookDescription: modalData.logbookDescription,
          categoryID: modalData.categoryID,
          contactType: modalData.contactType,
          logboekDateOccurence: formattedDate,
        },
      });

      if (response.status === 200) {
        // If there's a new file selected, upload it
        if (selectedFile) {
          await handleFileUpload(modalData.id);
        }
        handleClose();
        setRefresh(!refresh); // Refresh the list
        setSnackbarSeverity("success");
        setSnackbarMessage("Logboek item succesvol bijgewerkt");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error updating logbook item:", error);
      setSnackbarSeverity("error");
      setSnackbarMessage("Er is een fout opgetreden bij het bijwerken");
      setSnackbarOpen(true);
    }
  };

  const handleDoubleClick = async (row) => {
    console.log(row);

    setModalData({
      categoryID: row.categoryID,
      categoryname: row.categoryname,
      contactType: row.contactType,
      contactmomenttype: row.contactmomenttype,
      familyId: row.familyId,
      familyMemberFirstName: row.familyMemberFirstName,
      familyMemberId: row.familyMemberId,
      familyMemberSurname: row.familyMemberSurname,
      familyName: row.familyName,
      id: row.id,
      logboekTitle: row.logboekTitle,
      logbookDescription: row.logbookDescription,
      logbookDocLink: row.logbookDocLink,
    });
    await fetchAttachedFiles(row.id);
    setOpen(true);
  };

  const updateHandleOnChange = (e) => {
    const { name, value } = e.target;
    setModalData({ [name]: value });
    console.log(modalData);
  };

  const handleDateformat = (data) => {
    modalData.logboekDateOccurence = dayjs(
      modalData.logboekDateOccurence
    ).format("YYYY-MM-DD");
  };

  const handleBackToFamily = () => {
    navigate("/familycard", { state: { from } });
  };

  const handleFamilyUpdate = () => {
    navigate("/editfamily", { state: { from } });
  };

  const handleFilterChange = (e) => {
    setFilterValue(e.target.value);
  };

  const handleDeleteDialogOpen = (e, row) => {
    e.stopPropagation();
    setItemToDelete(row);
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
    setItemToDelete(null);
  };

  const handleDeleteItem = async () => {
    try {
      const response = await axios({
        method: "delete",
        url: "/api/deletelogbookitem/" + itemToDelete.id,
        headers: { Authorization: "Bearer " + (await getAccessTokens) },
      });

      if (response.status === 200) {
        setDeleteDialogOpen(false);
        setItemToDelete(null);
        setRefresh(!refresh); // Refresh the list
        setSnackbarSeverity("success");
        setSnackbarMessage("Logboek item succesvol verwijderd");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error deleting logbook item:", error);
      setSnackbarSeverity("error");
      setSnackbarMessage("Er is een fout opgetreden bij het verwijderen");
      setSnackbarOpen(true);
    }
  };

  const filteredLogboek = logboek.filter((row) => {
    const filterValueLowerCase = filterValue.toLowerCase();
    return (
      row.familyMemberFirstName.toLowerCase().includes(filterValueLowerCase) ||
      row.categoryname.toLowerCase().includes(filterValueLowerCase) ||
      row.contactmomenttype.toLowerCase().includes(filterValueLowerCase) ||
      row.logboekTitle.toLowerCase().includes(filterValueLowerCase)
    );
  });

  const handleNewItemDialogClose = (event, reason) => {
    if (reason && reason === "backdropClick") {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    handleNewItemClose();
  };

  const handleEditDialogClose = (event, reason) => {
    if (reason && reason === "backdropClick") {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    handleClose();
  };

  const handleDeleteConfirmClose = (event, reason) => {
    if (reason && reason === "backdropClick") {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    handleDeleteDialogClose();
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB in bytes

    if (file && file.size > MAX_FILE_SIZE) {
      setSnackbarSeverity("error");
      setSnackbarMessage("Bestand is te groot. Maximum grootte is 10MB");
      setSnackbarOpen(true);
      event.target.value = ''; // Clear the file input
      return;
    }

    setSelectedFile(file);
  };

  const handleFileUpload = async (logbookItemId) => {
    if (!selectedFile) return;

    const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB in bytes
    if (selectedFile.size > MAX_FILE_SIZE) {
      setSnackbarSeverity("error");
      setSnackbarMessage("Bestand is te groot. Maximum grootte is 10MB");
      setSnackbarOpen(true);
      return;
    }

    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('description', fileDescription);

    try {
      const token = await getAccessTokens;
      const userEmail = accounts[0]?.username;

      // Upload file
      const fileUploadResponse = await axios.post(`/api/files/upload/${modalData.familyMemberId}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "X-User-Email": userEmail,
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log("File upload response:", fileUploadResponse.data);

      // Get the file ID from the response
      const fileId = fileUploadResponse.data.file.id;
      console.log("File ID:", fileId);

      // Link file to logbook item
      const attachResponse = await axios.post(`/api/logbookitems/${logbookItemId}/attachfile`, {
        file_id: fileId,
        logbook_id: logbookItemId
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
          "X-User-Email": userEmail,
        },
      });
      
      console.log("Attach response:", attachResponse.data);

      setSelectedFile(null);
      setFileDescription("");
      setSnackbarSeverity("success");
      setSnackbarMessage("Bestand succesvol geüpload");
      setSnackbarOpen(true);

      // Refresh the list of attached files
      await fetchAttachedFiles(logbookItemId);
    } catch (error) {
      console.error("Error uploading file:", error);
      setSnackbarSeverity("error");
      setSnackbarMessage("Fout bij uploaden bestand");
      setSnackbarOpen(true);
    }
  };

  const handleDownload = async (file) => {
    try {
      const token = await getAccessTokens;
      const userEmail = accounts[0]?.username;

      const response = await axios.get(`/api/files/${file.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "X-User-Email": userEmail,
        },
        responseType: 'blob',
      });

      // Create a URL for the blob
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', file.filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
      setSnackbarSeverity("error");
      setSnackbarMessage("Fout bij downloaden bestand");
      setSnackbarOpen(true);
    }
  };

  const fetchAttachedFiles = async (logbookItemId) => {
    try {
      const token = await getAccessTokens;
      const userEmail = accounts[0]?.username;

      const response = await axios.get(`/api/logbookitem/${logbookItemId}/files`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "X-User-Email": userEmail,
        },
      });

      setAttachedFiles(response.data.files || []);
    } catch (error) {
      console.error("Error fetching attached files:", error);
    }
  };

  return (
    <Container 
      sx={{ 
        mt: 10, 
        p: 2 
      }}
    >
      <Box sx={{ 
        width: '100%',
        maxWidth: '100%',
        overflowX: 'auto'
      }}>
        <Box
          sx={{
            mb: 4,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
            gap: 2,
            maxWidth: '100%'
          }}
        >
          <Typography
            variant="h4"
            color="primary"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              flexGrow: 1,
              maxWidth: '100%'
            }}
          >
            <MenuBookIcon fontSize="large" />
            Logboek {from.familyName}
          </Typography>
          <Box sx={{ display: "flex", gap: 2 }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleBackToFamily}
              startIcon={<Diversity1OutlinedIcon />}
            >
              Terug naar familie
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleFamilyUpdate}
              startIcon={<Diversity1OutlinedIcon />}
            >
              Familiegegevens bijwerken
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNewItemOpen}
              startIcon={<UpgradeIcon />}
            >
              Nieuw Item
            </Button>
          </Box>
        </Box>

        <Box sx={{ mb: 3 }}>
          <TextField
            label="Zoeken in logboek"
            variant="outlined"
            value={filterValue}
            onChange={(e) => setFilterValue(e.target.value)}
            size="medium"
            sx={{ minWidth: 300 }}
            InputProps={{
              startAdornment: (
                <Box sx={{ color: "text.secondary", mr: 1 }}>
                  <MenuBookIcon />
                </Box>
              ),
            }}
          />
        </Box>

        <Paper
          elevation={3}
          sx={{
            width: "100%",
            overflow: "hidden",
            height: "calc(100vh - 250px)",
            display: "flex",
            flexDirection: "column",
            maxWidth: '100%'
          }}
        >
          <TableContainer>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold", backgroundColor: "primary.main", color: "white" }}>
                    Datum
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", backgroundColor: "primary.main", color: "white" }}>
                    Titel
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", backgroundColor: "primary.main", color: "white" }}>
                    Categorie
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", backgroundColor: "primary.main", color: "white" }}>
                    Contact
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", backgroundColor: "primary.main", color: "white" }}>
                    Familielid
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", backgroundColor: "primary.main", color: "white" }}>
                    Acties
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredLogboek.map((row) => (
                  <TableRow
                    key={row.id}
                    hover
                    sx={{
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "action.hover",
                      },
                    }}
                    onClick={() => handleDoubleClick(row)}
                  >
                    <TableCell>{dayjs(row.logboekDateOccurence).format("DD-MM-YYYY")}</TableCell>
                    <TableCell>{row.logboekTitle}</TableCell>
                    <TableCell>
                      <Chip
                        label={row.categoryname}
                        color="primary"
                        variant="outlined"
                        size="small"
                      />
                    </TableCell>
                    <TableCell>
                      <Chip
                        label={row.contactmomenttype}
                        color="secondary"
                        variant="outlined"
                        size="small"
                      />
                    </TableCell>
                    <TableCell>
                      {row.familyMemberFirstName} {row.familyMemberSurname}
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: 'flex', gap: 1 }}>
                        <IconButton
                          color="primary"
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDoubleClick(row);
                          }}
                        >
                          <MenuBookIcon />
                        </IconButton>
                        <IconButton
                          color="error"
                          size="small"
                          onClick={(e) => handleDeleteDialogOpen(e, row)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>

        <Dialog
          open={newItemOpen}
          onClose={handleNewItemDialogClose}
          maxWidth="md"
          fullWidth
          disableEscapeKeyDown
        >
          <DialogTitle sx={{ pb: 1 }}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="h6">Nieuw Logboek Item</Typography>
              <IconButton onClick={handleNewItemClose} size="small">
                <CloseFullscreenIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  label="Titel"
                  name="logboekTitle"
                  value={modalData.logboekTitle}
                  onChange={updateHandleOnChange}
                  fullWidth
                  required
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="Beschrijving"
                  name="logbookDescription"
                  value={modalData.logbookDescription}
                  onChange={updateHandleOnChange}
                  fullWidth
                  multiline
                  rows={4}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>Categorie</InputLabel>
                  <Select
                    name="categoryID"
                    value={modalData.categoryID}
                    onChange={updateHandleOnChange}
                    label="Categorie"
                  >
                    {categories && categories.map((category) => (
                      <MenuItem key={category.id} value={category.id}>
                        {category.categoryname || category.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>Contact Type</InputLabel>
                  <Select
                    name="contactType"
                    value={modalData.contactType}
                    onChange={updateHandleOnChange}
                    label="Contact Type"
                  >
                    {meetingType && meetingType.map((type) => (
                      <MenuItem key={type.id} value={type.id}>
                        {type.contactmomenttype || type.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>Familielid</InputLabel>
                  <Select
                    name="familyMemberId"
                    value={modalData.familyMemberId}
                    onChange={updateHandleOnChange}
                    label="Familielid"
                  >
                    {members.map((member) => (
                      <MenuItem key={member.id} value={member.id}>
                        {member.familyMemberFirstName} {member.familyMemberSurname}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Datum"
                    value={modalData.logboekDateOccurence}
                    onChange={(newValue) =>
                      handleDateformat(newValue)
                    }
                    renderInput={(params) => <TextField {...params} fullWidth />}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="Document Link"
                  name="logbookDocLink"
                  value={modalData.logbookDocLink}
                  onChange={updateHandleOnChange}
                  fullWidth
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12}>
                <Box sx={{ mt: 2, mb: 2 }}>
                  <Typography variant="subtitle1" gutterBottom>
                    Bestand toevoegen
                  </Typography>
                  <input
                    accept="*/*"
                    style={{ display: 'none' }}
                    id="raised-button-file"
                    type="file"
                    onChange={handleFileSelect}
                  />
                  <label htmlFor="raised-button-file">
                    <Button
                      variant="outlined"
                      component="span"
                      startIcon={<AttachFileIcon />}
                      sx={{ mr: 2 }}
                    >
                      Bestand kiezen
                    </Button>
                  </label>
                  {selectedFile && (
                    <Typography variant="body2" color="textSecondary">
                      Geselecteerd bestand: {selectedFile.name}
                    </Typography>
                  )}
                </Box>
                {selectedFile && (
                  <TextField
                    label="Bestandsomschrijving"
                    name="fileDescription"
                    value={fileDescription}
                    onChange={(e) => setFileDescription(e.target.value)}
                    fullWidth
                    variant="outlined"
                    sx={{ mt: 2 }}
                  />
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ p: 2, gap: 1 }}>
            <Button onClick={handleNewItemClose} variant="outlined" color="secondary">
              Annuleren
            </Button>
            <Button onClick={handleNewItemSave} variant="contained" color="primary">
              Opslaan
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={open}
          onClose={handleEditDialogClose}
          maxWidth="md"
          fullWidth
          disableEscapeKeyDown
        >
          <DialogTitle sx={{ pb: 1 }}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="h6">Logboek Item Bewerken</Typography>
              <IconButton onClick={handleClose} size="small">
                <CloseFullscreenIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  label="Titel"
                  name="logboekTitle"
                  value={modalData.logboekTitle}
                  onChange={updateHandleOnChange}
                  fullWidth
                  required
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="Beschrijving"
                  name="logbookDescription"
                  value={modalData.logbookDescription}
                  onChange={updateHandleOnChange}
                  fullWidth
                  multiline
                  rows={4}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>Categorie</InputLabel>
                  <Select
                    name="categoryID"
                    value={modalData.categoryID}
                    onChange={updateHandleOnChange}
                    label="Categorie"
                  >
                    {categories && categories.map((category) => (
                      <MenuItem key={category.id} value={category.id}>
                        {category.categoryname || category.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>Contact Type</InputLabel>
                  <Select
                    name="contactType"
                    value={modalData.contactType}
                    onChange={updateHandleOnChange}
                    label="Contact Type"
                  >
                    {meetingType && meetingType.map((type) => (
                      <MenuItem key={type.id} value={type.id}>
                        {type.contactmomenttype || type.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Box sx={{ mt: 2, mb: 2 }}>
                  <Typography variant="subtitle1" gutterBottom>
                    Bijlagen
                  </Typography>
                  {attachedFiles.length > 0 ? (
                    <List>
                      {attachedFiles.map((file) => (
                        <ListItem key={file.id}>
                          <ListItemIcon>
                            <AttachFileIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={file.filename}
                            secondary={file.description}
                          />
                          <ListItemSecondaryAction>
                            <IconButton edge="end" onClick={() => handleDownload(file)}>
                              <FileDownloadIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      ))}
                    </List>
                  ) : (
                    <Typography variant="body2" color="textSecondary">
                      Geen bijlagen
                    </Typography>
                  )}
                </Box>
                <Box sx={{ mt: 2 }}>
                  <Typography variant="subtitle1" gutterBottom>
                    Nieuwe bijlage toevoegen
                  </Typography>
                  <input
                    accept="*/*"
                    style={{ display: 'none' }}
                    id="raised-button-file-edit"
                    type="file"
                    onChange={handleFileSelect}
                  />
                  <label htmlFor="raised-button-file-edit">
                    <Button
                      variant="outlined"
                      component="span"
                      startIcon={<AttachFileIcon />}
                      sx={{ mr: 2 }}
                    >
                      Bestand kiezen
                    </Button>
                  </label>
                  {selectedFile && (
                    <Typography variant="body2" color="textSecondary">
                      Geselecteerd bestand: {selectedFile.name}
                    </Typography>
                  )}
                </Box>
                {selectedFile && (
                  <TextField
                    label="Bestandsomschrijving"
                    name="fileDescription"
                    value={fileDescription}
                    onChange={(e) => setFileDescription(e.target.value)}
                    fullWidth
                    variant="outlined"
                    sx={{ mt: 2 }}
                  />
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ p: 2, gap: 1 }}>
            <Button onClick={handleClose} variant="outlined" color="secondary">
              Annuleren
            </Button>
            <Button
              onClick={() => handleFileUpload(modalData.id)}
              variant="contained"
              color="primary"
              disabled={!selectedFile}
            >
              Bestand uploaden
            </Button>
            <Button onClick={handleUpdateItem} variant="contained" color="primary">
              Opslaan
            </Button>
          </DialogActions>
        </Dialog>

        {/* Delete Confirmation Dialog */}
        <Dialog
          open={deleteDialogOpen}
          onClose={handleDeleteConfirmClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          disableEscapeKeyDown
        >
          <DialogTitle id="alert-dialog-title">
            {"Weet je zeker dat je dit logboek item wilt verwijderen?"}
          </DialogTitle>
          <DialogContent>
            <Typography variant="body1" id="alert-dialog-description">
              Deze actie kan niet ongedaan worden gemaakt.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteDialogClose} color="primary">
              Annuleren
            </Button>
            <Button onClick={handleDeleteItem} color="error" autoFocus>
              Verwijderen
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar 
          open={snackbarOpen} 
          autoHideDuration={6000} 
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </Container>
  );
}

export default FamilyLogboek;
