import React, { useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Container, Typography, Paper } from "@mui/material";
import { LoginRounded } from "@mui/icons-material";
import logo from "../assets/Vwwbd logo.jpg";

function Login() {
  const { instance, accounts } = useMsal();
  const navigate = useNavigate();

  useEffect(() => {
    if (accounts.length > 0) {
      navigate("/");
    }
  }, [accounts, navigate]);

  const handleLogin = () => {
    instance.loginRedirect();
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#f5f5f5",
        py: 4,
      }}
    >
      <Container maxWidth="sm">
        <Paper
          elevation={3}
          sx={{
            p: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            borderRadius: 2,
            backgroundColor: "#ffffff",
          }}
        >
          {/* Logo or Image */}
          <Box
            component="img"
            src={logo}
            alt="VWG Logo"
            sx={{
              width: 180,
              height: "auto",
              mb: 3,
            }}
          />

          <Typography
            variant="h4"
            component="h1"
            gutterBottom
            sx={{
              fontWeight: 600,
              color: "primary.main",
              textAlign: "center",
            }}
          >
            Welkom bij VWG IDS
          </Typography>

          <Typography
            variant="subtitle1"
            sx={{
              mb: 4,
              textAlign: "center",
              color: "text.secondary",
            }}
          >
            Log in met uw Microsoft account om door te gaan
          </Typography>

          <Button
            variant="contained"
            size="large"
            onClick={handleLogin}
            startIcon={<LoginRounded />}
            sx={{
              px: 4,
              py: 1.5,
              borderRadius: 2,
              textTransform: "none",
              fontSize: "1.1rem",
              boxShadow: 2,
              "&:hover": {
                boxShadow: 4,
                transform: "translateY(-2px)",
              },
              transition: "all 0.2s ease-in-out",
            }}
          >
            Inloggen met Microsoft
          </Button>
        </Paper>
      </Container>
    </Box>
  );
}

export default Login;
