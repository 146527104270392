import React from "react";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

function Spinner({ message }) {
	return (
		<Stack
			sx={{ color: "grey.500", marginTop: "30%", marginLeft: "30%" }}
			spacing={2}
			direction="row"
		>
			<Typography variant="h6" color="primary">
				{message ? message : "Loading..."}
			</Typography>
			<CircularProgress size={40} color="primary" />
		</Stack>
	);
}

export default Spinner;
