import React, { useState, useEffect } from 'react';
import {
  Container,
  Paper,
  Typography,
  Tabs,
  Tab,
  Box,
  Button,
  Grid,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Snackbar,
  Alert
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAuth } from "../authentication/useAuth";
import axios from 'axios';

function Dienstverleners() {
  const { getAccessToken } = useAuth();
  const token = getAccessToken();

  // State management
  const [activeTab, setActiveTab] = useState(0);
  const [tandartsen, setTandartsen] = useState([]);
  const [huisartsen, setHuisartsen] = useState([]);
  const [verloskundigen, setVerloskundigen] = useState([]);
  const [apotheken, setApotheken] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogType, setDialogType] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);
  const [formData, setFormData] = useState({});
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  // Fetch data
  useEffect(() => {
    fetchAllData();
  }, []);

  const fetchAllData = async () => {
    const getToken = await token;
    try {
      const [tandartsenRes, huisartsenRes, verloskundigenRes, apothekenRes] = await Promise.all([
        axios.get('/api/onderhoud/tandartsen', {
          headers: { Authorization: `Bearer ${getToken}` }
        }),
        axios.get('/api/onderhoud/huisartsen', {
          headers: { Authorization: `Bearer ${getToken}` }
        }),
        axios.get('/api/onderhoud/verloskundigen', {
          headers: { Authorization: `Bearer ${getToken}` }
        }),
        axios.get('/api/onderhoud/apotheken', {
          headers: { Authorization: `Bearer ${getToken}` }
        })
      ]);

      setTandartsen(tandartsenRes.data.tandartsen || []);
      setHuisartsen(huisartsenRes.data.huisartsen || []);
      setVerloskundigen(verloskundigenRes.data.verloskundigen || []);
      setApotheken(apothekenRes.data.apotheken || []);
    } catch (error) {
      showSnackbar('Error fetching data', 'error');
    }
  };

  // Form handlers
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleAdd = (type) => {
    setDialogType(type);
    setSelectedItem(null);
    setFormData({});
    setOpenDialog(true);
  };

  const handleEdit = (type, item) => {
    setDialogType(type);
    setSelectedItem(item);
    setFormData(item);
    setOpenDialog(true);
  };

  const handleDelete = async (type, id) => {
    if (!window.confirm('Weet u zeker dat u dit item wilt verwijderen?')) {
      return;
    }

    const getToken = await token;
    try {
      await axios.delete(`/api/onderhoud/${type}/${id}`, {
        headers: { Authorization: `Bearer ${getToken}` }
      });
      showSnackbar('Item succesvol verwijderd', 'success');
      fetchAllData();
    } catch (error) {
      showSnackbar('Error bij het verwijderen', 'error');
    }
  };

  const handleSubmit = async () => {
    const getToken = await token;
    try {
      const method = selectedItem ? 'PUT' : 'POST';
      const url = selectedItem 
        ? `/api/onderhoud/${dialogType}/${selectedItem.id}`
        : `/api/onderhoud/${dialogType}/create`;

      await axios({
        method,
        url,
        headers: { Authorization: `Bearer ${getToken}` },
        data: formData
      });

      showSnackbar(`Item succesvol ${selectedItem ? 'bijgewerkt' : 'toegevoegd'}`, 'success');
      setOpenDialog(false);
      fetchAllData();
    } catch (error) {
      showSnackbar('Error bij het opslaan', 'error');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const showSnackbar = (message, severity) => {
    setSnackbar({ open: true, message, severity });
  };

  // Render helpers
  const getFields = (type) => {
    switch (type) {
      case 'tandarts':
        return [
          { name: 'tandartsPraktijk', label: 'Praktijk' },
          { name: 'tandartsStraat', label: 'Straat' },
          { name: 'tandartsHuisnummer', label: 'Huisnummer' },
          { name: 'tandartsPostcode', label: 'Postcode' },
          { name: 'tandartsPlaats', label: 'Plaats' },
          { name: 'tandartsTelefoon', label: 'Telefoon' },
          { name: 'tandartsNoodnummer', label: 'Noodnummer' },
          { name: 'tandartsEmail', label: 'Email' },
          { name: 'tandartsWebsite', label: 'Website' },
          { name: 'tandartsContactpersoon', label: 'Contactpersoon' }
        ];
      case 'huisarts':
        return [
          { name: 'huisartsPraktijk', label: 'Praktijk' },
          { name: 'huisartsStraat', label: 'Straat' },
          { name: 'huisartsHuisnummer', label: 'Huisnummer' },
          { name: 'huisartsPostcode', label: 'Postcode' },
          { name: 'huisartsPlaats', label: 'Plaats' },
          { name: 'huisartsTelefoon', label: 'Telefoon' },
          { name: 'huisartsNoodnummer', label: 'Noodnummer' },
          { name: 'huisartsEmail', label: 'Email' },
          { name: 'huisartsWebsite', label: 'Website' },
          { name: 'huisartsContactpersoon', label: 'Contactpersoon' }
        ];
      case 'verloskundige':
        return [
          { name: 'verloskundigePraktijk', label: 'Praktijk' },
          { name: 'verloskundigeNaam', label: 'Naam' },
          { name: 'verloskundigeStraat', label: 'Straat' },
          { name: 'verloskundigeHuisnummer', label: 'Huisnummer' },
          { name: 'verloskundigePostcode', label: 'Postcode' },
          { name: 'verloskundigePlaats', label: 'Plaats' },
          { name: 'verloskundigeTelefoon', label: 'Telefoon' },
          { name: 'verloskundigeNoodnummer', label: 'Noodnummer' },
          { name: 'verloskundigeEmail', label: 'Email' },
          { name: 'verloskundigeWebsite', label: 'Website' }
        ];
      case 'apotheek':
        return [
          { name: 'apotheekNaam', label: 'Naam' },
          { name: 'apotheekStraat', label: 'Straat' },
          { name: 'apotheekHuisnummer', label: 'Huisnummer' },
          { name: 'apotheekPostcode', label: 'Postcode' },
          { name: 'apotheekPlaats', label: 'Plaats' },
          { name: 'apotheekTelefoon', label: 'Telefoon' },
          { name: 'apotheekNoodnummer', label: 'Noodnummer' },
          { name: 'apotheekEmail', label: 'Email' },
          { name: 'apotheekWebsite', label: 'Website' }
        ];
      default:
        return [];
    }
  };

  const renderProviderList = (type, data) => (
    <Grid container spacing={2}>
      {data.map((item) => (
        <Grid item xs={12} sm={6} md={4} key={item.id}>
          <Paper
            elevation={2}
            sx={{
              p: 2,
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              '&:hover': {
                boxShadow: 6
              }
            }}
          >
            <Box sx={{ mb: 2 }}>
              <Typography variant="h6" component="div" gutterBottom>
                {item[`${type}${type === 'apotheek' ? 'Naam' : 'Praktijk'}`]}
              </Typography>
              <Typography color="text.secondary" gutterBottom>
                {item[`${type}Straat`]} {item[`${type}Huisnummer`]}
              </Typography>
              <Typography color="text.secondary" gutterBottom>
                {item[`${type}Postcode`]} {item[`${type}Plaats`]}
              </Typography>
              <Typography color="text.secondary">
                Tel: {item[`${type}Telefoon`]}
              </Typography>
            </Box>
            <Box sx={{ mt: 'auto', display: 'flex', gap: 1 }}>
              <IconButton 
                onClick={() => handleEdit(type, item)} 
                size="small"
                title="Bewerken"
              >
                <EditIcon />
              </IconButton>
              <IconButton 
                onClick={() => handleDelete(type, item.id)} 
                size="small" 
                color="error"
                title="Verwijderen"
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );

  return (
    <Container maxWidth="lg" sx={{ py: 4, mt: 10, ml:10 }}>
      <Paper sx={{ p: 3 }}>
        <Typography variant="h4" sx={{ mb: 3 }}>
          Dienstverleners
        </Typography>

        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
          <Tabs 
            value={activeTab} 
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Tandartsen" />
            <Tab label="Huisartsen" />
            <Tab label="Verloskundigen" />
            <Tab label="Apotheken" />
          </Tabs>
        </Box>

        <Box sx={{ mb: 3 }}>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => handleAdd(
              activeTab === 0 ? 'tandarts' :
              activeTab === 1 ? 'huisarts' :
              activeTab === 2 ? 'verloskundige' :
              'apotheek'
            )}
          >
            Toevoegen
          </Button>
        </Box>

        <Box sx={{ mt: 2 }}>
          {activeTab === 0 && renderProviderList('tandarts', tandartsen)}
          {activeTab === 1 && renderProviderList('huisarts', huisartsen)}
          {activeTab === 2 && renderProviderList('verloskundige', verloskundigen)}
          {activeTab === 3 && renderProviderList('apotheek', apotheken)}
        </Box>

        <Dialog 
          open={openDialog} 
          onClose={() => setOpenDialog(false)} 
          maxWidth="md" 
          fullWidth
        >
          <DialogTitle>
            {selectedItem ? 'Bewerken' : 'Toevoegen'} {dialogType}
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              {getFields(dialogType).map((field) => (
                <Grid item xs={12} sm={6} key={field.name}>
                  <TextField
                    fullWidth
                    label={field.label}
                    name={field.name}
                    value={formData[field.name] || ''}
                    onChange={handleInputChange}
                    variant="outlined"
                  />
                </Grid>
              ))}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)}>
              Annuleren
            </Button>
            <Button onClick={handleSubmit} variant="contained">
              Opslaan
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Alert 
            onClose={() => setSnackbar({ ...snackbar, open: false })} 
            severity={snackbar.severity}
            variant="filled"
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Paper>
    </Container>
  );
}

export default Dienstverleners;
