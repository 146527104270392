import React from "react";
import Container from "@mui/material/Container";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { TextField, Button, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useAuth } from "../authentication/useAuth.js";
import axios from "axios";
import Grid from "@mui/material/Grid";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import AddIcon from "@mui/icons-material/Add";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import LaunchIcon from "@mui/icons-material/Launch";
import IconButton from "@mui/material/IconButton";

function FamilyMemberAccounts() {
  const location = useLocation();
  const { from } = location.state;
  const navigate = useNavigate();
  const memberId = from.id;
  const [isLoading, setIsLoading] = useState(true);
  const [accounts, setAccounts] = useState([]); // Changed to array initialization

  const [newAccount, setNewAccount] = useState({
    accountProvider: "",
    accountName: "",
    accountPassword: "",
    accountNumber: "",
    accountProviderLink: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewAccount((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const { getAccessToken } = useAuth();
  const token = getAccessToken();

  const getAccountList = async () => {
    axios({
      method: "GET",
      url: "/api/familymemberaccounts/" + memberId,
      headers: { Authorization: "Bearer " + (await token) },
    })
      .then((response) => {
        if (response.status === 200) {
          setAccounts(response.data.accounts);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getAccountList();
  }, []);

  const handleAddAccount = async () => {
    try {
      const response = await axios({
        method: "POST",
        url: "/api/addfamilymemberaccount/" + from.id,
        data: newAccount,
        headers: { Authorization: "Bearer " + (await token) },
      });
      
      if (response.status === 200) {
        await getAccountList(); // Refresh the list after adding
        setNewAccount({
          accountProvider: "",
          accountName: "",
          accountPassword: "",
          accountNumber: "",
          accountProviderLink: "",
        });
      }
    } catch (error) {
      console.error("Error adding account:", error);
    }
  };

  const handleStepBack = () => {
    navigate(-1);
  };

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [accountToDelete, setAccountToDelete] = useState(null);

  const handleDeleteDialogOpen = (account) => {
    setAccountToDelete(account);
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
    setAccountToDelete(null);
  };

  const handleDeleteAccount = async (id) => {
    try {
      const response = await axios({
        method: "POST",
        url: "/api/deletefamilymemberaccount/" + id,
        headers: { Authorization: "Bearer " + (await token) },
      });
      
      if (response.status === 200) {
        await getAccountList();
        handleDeleteDialogClose();
        setSnackbarSeverity("success");
        setSnackbarMessage("Account succesvol verwijderd");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error deleting account:", error);
      setSnackbarSeverity("error");
      setSnackbarMessage("Er is een fout opgetreden bij het verwijderen");
      setSnackbarOpen(true);
    }
  };

  const [open, setOpen] = useState(false);
  const [editAccount, setEditAccount] = useState({
    id: "",
    accountProvider: "",
    accountName: "",
    accountPassword: "",
    accountNumber: "",
    accountProviderLink: "",
  });

  const handleClickOpen = (account) => {
    setEditAccount(account);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditAccount((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    try {
      const response = await axios({
        method: "POST",
        url: "/api/updatefamilymemberaccount/" + editAccount.id,
        data: editAccount,
        headers: { Authorization: "Bearer " + (await token) },
      });
      
      if (response.status === 200) {
        await getAccountList(); // Refresh the list after updating
        handleClose();
      }
    } catch (error) {
      console.error("Error updating account:", error);
      setSnackbarSeverity("error");
      setSnackbarMessage("Error updating account");
      setSnackbarOpen(true);
    }
  };

  const handleEditDialogClose = (event, reason) => {
    if (reason && reason === "backdropClick") {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    handleClose();
  };

  const handleDeleteConfirmClose = (event, reason) => {
    if (reason && reason === "backdropClick") {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    handleDeleteDialogClose();
  };

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const [filterValue, setFilterValue] = useState("");

  const filteredAccounts = accounts.filter((account) => {
    const searchValue = filterValue.toLowerCase();
    return (
      account.accountProvider?.toLowerCase().includes(searchValue) ||
      account.accountName?.toLowerCase().includes(searchValue) ||
      account.accountNumber?.toLowerCase().includes(searchValue)
    );
  });

  return (
    <Container maxWidth="lg" sx={{ py: { xs: 2, sm: 3, md: 4  }, mt: 10 }}>
      <Paper elevation={2} sx={{ p: { xs: 2, sm: 3 }, borderRadius: { xs: 1, sm: 2 } }}>
        <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap', gap: 2 }}>
          <Typography variant="h5">
            Accountpagina voor {from.familyMemberFirstName} {from.familyMemberSurname}
          </Typography>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button
              variant="outlined"
              startIcon={<ArrowBackIcon />}
              onClick={handleStepBack}
            >
              Terug
            </Button>
          </Box>
        </Box>

        <Grid container spacing={2} sx={{ mb: 3 }}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              size="small"
              label="Zoeken in accounts"
              value={filterValue}
              onChange={(e) => setFilterValue(e.target.value)}
              InputProps={{
                startAdornment: (
                  <Box sx={{ color: 'text.secondary', mr: 1 }}>
                    <SearchIcon />
                  </Box>
                ),
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          {filteredAccounts.map((account) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={account.id}>
              <Paper
                elevation={1}
                sx={{
                  p: 2,
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  '&:hover': {
                    boxShadow: 3,
                  },
                }}
              >
                <Box sx={{ mb: 1 }}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 0.5 }}>
                    {account.accountProvider}
                  </Typography>
                  <Box sx={{ mb: 0.5 }}>
                    <Typography variant="caption" color="text.secondary" display="block">
                      Gebruikersnaam:
                    </Typography>
                    <Typography variant="body2">
                      {account.accountName}
                    </Typography>
                  </Box>
                  <Box sx={{ mb: 0.5 }}>
                    <Typography variant="caption" color="text.secondary" display="block">
                      Wachtwoord:
                    </Typography>
                    <Typography variant="body2">
                      {account.accountPassword}
                    </Typography>
                  </Box>
                  <Box sx={{ mb: 0.5 }}>
                    <Typography variant="caption" color="text.secondary" display="block">
                      Account nummer:
                    </Typography>
                    <Typography variant="body2">
                      {account.accountNumber}
                    </Typography>
                  </Box>
                  {account.accountProviderLink && (
                    <Box>
                      <Typography variant="caption" color="text.secondary" display="block">
                        Website:
                      </Typography>
                      <Typography 
                        variant="body2" 
                        sx={{ 
                          color: 'primary.main',
                          cursor: 'pointer',
                          '&:hover': {
                            textDecoration: 'underline'
                          }
                        }}
                        onClick={() => window.open(account.accountProviderLink, '_blank')}
                      >
                        {account.accountProviderLink}
                      </Typography>
                    </Box>
                  )}
                </Box>
                <Box sx={{ mt: 'auto', pt: 1, display: 'flex', gap: 1 }}>
                  <IconButton
                    size="small"
                    onClick={() => handleClickOpen(account)}
                    color="primary"
                    title="Bewerken"
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={() => handleDeleteDialogOpen(account)}
                    color="error"
                    title="Verwijderen"
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                  {account.accountProviderLink && (
                    <IconButton
                      size="small"
                      onClick={() => window.open(account.accountProviderLink, '_blank')}
                      color="primary"
                      title="Open website"
                    >
                      <LaunchIcon fontSize="small" />
                    </IconButton>
                  )}
                </Box>
              </Paper>
            </Grid>
          ))}
          
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Paper
              elevation={1}
              sx={{
                p: 2,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                border: '2px dashed',
                borderColor: 'divider',
                '&:hover': {
                  borderColor: 'primary.main',
                  cursor: 'pointer',
                },
              }}
              onClick={handleAddAccount}
            >
              <Box sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center', 
                justifyContent: 'center',
                height: '100%',
                gap: 1
              }}>
                <AddIcon color="primary" />
                <Typography color="primary">Nieuw Account</Typography>
              </Box>
            </Paper>
          </Grid>
        </Grid>

        <Dialog
          open={open}
          onClose={handleEditDialogClose}
          maxWidth="md"
          fullWidth
          disableEscapeKeyDown
        >
          <DialogTitle>Edit Account</DialogTitle>
          <DialogContent>
            <TextField
              label="Account Provider"
              name="accountProvider"
              value={editAccount.accountProvider}
              onChange={handleEditChange}
              fullWidth
              margin="dense"
            />
            <TextField
              label="Account Gebruikersnaam"
              name="accountName"
              value={editAccount.accountName}
              onChange={handleEditChange}
              fullWidth
              margin="dense"
            />
            <TextField
              label="Account Wachtwoord"
              name="accountPassword"
              value={editAccount.accountPassword}
              onChange={handleEditChange}
              fullWidth
              margin="dense"
            />
            <TextField
              label="Account Nummer (als van toep.)"
              name="accountNumber"
              value={editAccount.accountNumber}
              onChange={handleEditChange}
              fullWidth
              margin="dense"
            />
            <TextField
              label="Link naar de website"
              name="accountProviderLink"
              value={editAccount.accountProviderLink}
              onChange={handleEditChange}
              fullWidth
              margin="dense"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleSave} variant="contained">
              Save
            </Button>
          </DialogActions>
        </Dialog>

        {/* Delete Confirmation Dialog */}
        <Dialog
          open={deleteDialogOpen}
          onClose={handleDeleteConfirmClose}
          aria-labelledby="delete-dialog-title"
          aria-describedby="delete-dialog-description"
          disableEscapeKeyDown
        >
          <DialogTitle id="delete-dialog-title">
            {"Weet je zeker dat je dit account wilt verwijderen?"}
          </DialogTitle>
          <DialogContent>
            <Typography variant="body1" id="delete-dialog-description">
              Je staat op het punt om het account "{accountToDelete?.accountProvider}" te verwijderen. 
              Deze actie kan niet ongedaan worden gemaakt.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteDialogClose} color="primary">
              Annuleren
            </Button>
            <Button 
              onClick={() => handleDeleteAccount(accountToDelete?.id)} 
              color="error" 
              variant="contained"
              autoFocus
            >
              Verwijderen
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity={snackbarSeverity}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Paper>
    </Container>
  );
}

export default FamilyMemberAccounts;
