import React from "react";
import { Box, Container, Typography } from "@mui/material";

function Kennisbank() {
  return (
    <div>
      <Container>
        <Box>
          <Typography variant="h4" color="initial" sx={{ marginTop: 10 }}>
            Kennisbank
          </Typography>
        </Box>
      </Container>
    </div>
  );
}

export default Kennisbank;
