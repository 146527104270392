import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-router-dom";
import Diversity1OutlinedIcon from "@mui/icons-material/Diversity1Outlined";
import LocalLibraryOutlinedIcon from "@mui/icons-material/LocalLibraryOutlined";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import MemoryIcon from "@mui/icons-material/Memory";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import EngineeringIcon from "@mui/icons-material/Engineering";
import CottageIcon from "@mui/icons-material/Cottage";
import LogoutIcon from '@mui/icons-material/Logout';
import logo from "../assets/Vwwbd logo.jpg";
import { useMsal } from "@azure/msal-react";
import { BrowserUtils } from "@azure/msal-browser";
import { Avatar, Button } from "@mui/material";

// Define menu items as a constant object
const MenuItems = {
	begeleiding: [
		{
			id: 0,
			title: "Home",
			path: "/",
			icon: <CottageIcon />,
			active: "",
		},
		{
			id: 1,
			title: "Families in begeleiding",
			path: "/families",
			icon: <Diversity1OutlinedIcon />,
			active: "",
		},
		{
			id: 2,
			title: "Mijn begeleiding",
			path: "/mijnbegeleiding",
			icon: <FactCheckOutlinedIcon />,
			active: "",
		},
		{
			id: 3,
			title: "Kennisbank",
			path: "/kennisbank",
			icon: <LocalLibraryOutlinedIcon />,
			active: "",
		},
	],
	bureau: [
		{
			id: 1,
			title: "Onderhoud vragenlijst",
			path: "/vragenlijst",
			icon: <QuestionAnswerOutlinedIcon />,
		},
		{
			id: 2,
			title: "Gebruikers",
			path: "/gebruikers",
			icon: <GroupAddOutlinedIcon />,
		},
	],
	applicatie: [
		{
			id: 1,
			title: "Meldt een probleem",
			path: "/probleem",
			icon: <MemoryIcon />,
		},
		{
			id: 2,
			title: "Handleiding",
			path: "/handleiding",
			icon: <MenuBookIcon />,
		},
		{
			id: 3,
			title: "Onderhoud",
			path: "/onderhoud",
			icon: <EngineeringIcon />,
		},
	],
};

const drawerWidth = {
  xs: 240,
  sm: 280
};

const openedMixin = (theme) => ({
  width: drawerWidth[theme.breakpoints.down('sm') ? 'xs' : 'sm'],
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  [theme.breakpoints.down('sm')]: {
    width: `calc(${theme.spacing(6)} + 1px)`
  },
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(7)} + 1px)`
  }
});

const DrawerHeader = styled("div")(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	justifyContent: "flex-end",
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: 'none',
  borderBottom: `1px solid ${theme.palette.divider}`,
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: theme.breakpoints.down('sm') ? drawerWidth.xs : drawerWidth.sm,
    width: `calc(100% - ${theme.breakpoints.down('sm') ? drawerWidth.xs : drawerWidth.sm}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
	width: theme.breakpoints.down('sm') ? drawerWidth.xs : drawerWidth.sm,
	flexShrink: 0,
	whiteSpace: 'nowrap',
	boxSizing: 'border-box',
	...(open && {
		...openedMixin(theme),
		'& .MuiDrawer-paper': openedMixin(theme)
	}),
	...(!open && {
		...closedMixin(theme),
		'& .MuiDrawer-paper': closedMixin(theme)
	})
}));

const ResponsiveDrawer = React.memo(() => {
	const theme = useTheme();
	const [open, setOpen] = React.useState(false);
	const { instance, accounts } = useMsal();
	const isAuthenticated = accounts.length > 0;

	const handleDrawerOpen = React.useCallback(() => {
		setOpen(true);
	}, []);

	const handleDrawerClose = React.useCallback(() => {
		setOpen(false);
	}, []);

	const Logout = React.useCallback(() => {
		console.log("Logging out");
		instance.logoutRedirect({
			account: instance.getActiveAccount(),
			onRedirectNavigate: () => !BrowserUtils.isInIframe(),
		});
	}, [instance]);

	// Memoize the list items to prevent re-renders
	const renderListItems = React.useCallback((items) => {
		if (!items) return null;
		return items.map((item) => (
			<ListItem key={item.id} disablePadding sx={{ display: "block" }}>
				<ListItemButton
					component={Link}
					sx={{
						minHeight: 48,
						justifyContent: open ? "initial" : "center",
						px: 2.5,
					}}
					to={item.path}
				>
					<ListItemIcon
						sx={{
							minWidth: 0,
							mr: open ? 3 : "auto",
							justifyContent: "center",
						}}
					>
						{item.icon}
					</ListItemIcon>
					<ListItemText
						primary={item.title}
						sx={{ opacity: open ? 1 : 0 }}
					/>
				</ListItemButton>
			</ListItem>
		));
	}, [open]);

	// Memoize the avatar section
	const avatarSection = React.useMemo(() => (
		<Box sx={{ 
			display: 'flex', 
			alignItems: 'center',
			gap: 1,
			display: { xs: 'none', sm: 'flex' }
		}}>
			<Avatar 
				sx={{ 
					bgcolor: 'primary.main',
					width: 32,
					height: 32,
					fontSize: '0.875rem'
				}}
			>
				{accounts[0]?.name?.charAt(0) || 'U'}
			</Avatar>
			<Typography variant="body2" color="text.secondary">
				{accounts[0]?.name || "Unknown user"}
			</Typography>
		</Box>
	), [accounts]);

	// Memoize the drawer content
	const drawerContent = React.useMemo(() => (
		<>
			<DrawerHeader>
				<IconButton onClick={handleDrawerClose}>
					{theme.direction === "rtl" ? (
						<ChevronRightIcon />
					) : (
						<ChevronLeftIcon />
					)}
				</IconButton>
			</DrawerHeader>
			<Divider />
			<List>
				{renderListItems(MenuItems.begeleiding)}
			</List>
			<Divider />
			<List>
				{renderListItems(MenuItems.bureau)}
			</List>
			<Divider />
			<List>
				{renderListItems(MenuItems.applicatie)}
			</List>
		</>
	), [theme.direction, handleDrawerClose, renderListItems]);

	return (
		<Box sx={{ display: "flex" }}>
			<CssBaseline />
			<AppBar position="fixed" open={open}>
				<Toolbar sx={{ justifyContent: 'space-between' }}>
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<IconButton
							color="primary"
							aria-label="open drawer"
							onClick={handleDrawerOpen}
							edge="start"
							sx={{
								marginRight: 2,
								...(open && { display: "none" }),
							}}
						>
							<MenuIcon />
						</IconButton>
						<Box 
							component="img"
							src={logo}
							sx={{
								height: 40,
								width: 40,
								borderRadius: 1,
								marginRight: 2,
								display: { xs: 'none', sm: 'block' }
							}}
							alt="Logo Stichting Vluchtelingenwerkgroep Wijk bij Duurstede"
						/>
						<Typography 
							variant="h6" 
							noWrap 
							component="div" 
							color="primary"
							sx={{ 
								fontWeight: 600,
								fontSize: { xs: '1rem', sm: '1.25rem' }
							}}
						>
							VwWbD - Begeleiding
						</Typography>
					</Box>

					<Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
						{avatarSection}

						{isAuthenticated && (
							<Button
								variant="outlined"
								size="small"
								color="primary"
								onClick={Logout}
								startIcon={<LogoutIcon />}
								sx={{ 
									minWidth: { xs: 'auto', sm: '100px' },
									'& .MuiButton-startIcon': { 
										display: { xs: 'none', sm: 'inline-flex' }
									}
								}}
							>
								<Box sx={{ display: { xs: 'none', sm: 'block' } }}>
									Uitloggen
								</Box>
								<LogoutIcon sx={{ display: { xs: 'block', sm: 'none' } }} />
							</Button>
						)}
					</Box>
				</Toolbar>
			</AppBar>

			<Drawer
				variant="permanent"
				open={open}
				PaperProps={{
					elevation: 0,
					sx: {
						mt: '64px',
						height: 'calc(100% - 64px)',
						'& .MuiListItemButton-root': {
							borderRadius: 1,
							mx: 1,
							my: 0.5,
						},
						'& .MuiListItemButton-root:hover': {
							backgroundColor: 'action.hover',
						},
						'& .MuiListItemIcon-root': {
							color: 'primary.main',
						},
					},
				}}
			>
				{drawerContent}
			</Drawer>
		</Box>
	);
});

ResponsiveDrawer.displayName = 'ResponsiveDrawer';

export default ResponsiveDrawer;
