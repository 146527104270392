import React, { useEffect, useState, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Container,
  Paper,
  Typography,
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Box,
  Snackbar,
  Alert,
} from "@mui/material";
import {
  CloudUpload as CloudUploadIcon,
  Download as DownloadIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  ArrowBack,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import dayjs from "dayjs";

// Styled component for the file input
const Input = styled('input')({
  display: 'none',
});

function FamilyFiles() {
  const location = useLocation();
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const { from } = location.state || {};

  const [files, setFiles] = useState([]);
  const [familyMembers, setFamilyMembers] = useState([]);
  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDownloadDialog, setOpenDownloadDialog] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedMember, setSelectedMember] = useState('');
  const [description, setDescription] = useState('');
  const [editingFile, setEditingFile] = useState(null);
  const [fileToDownload, setFileToDownload] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [isDragging, setIsDragging] = useState(false);

  const GetAccessTokens = useCallback(async () => {
    const request = {
      scopes: ["api://e8ee6ebf-41ff-4a7a-8d4b-2b2b4aa05d8e/User.Read"],
      account: accounts[0],
    };
    try {
      const response = await instance.acquireTokenSilent(request);
      return response.accessToken;
    } catch (e) {
      if (e instanceof InteractionRequiredAuthError) {
        return instance.acquireTokenRedirect(request);
      }
    }
  }, [instance, accounts]);

  const fetchData = useCallback(async () => {
    if (!from?.id) {
      console.error("Missing family data:", from);
      return;
    }

    try {
      const token = await GetAccessTokens();
      const userEmail = accounts[0]?.username;

      const [filesResponse, membersResponse] = await Promise.all([
        axios.get(`/api/files/family/${from.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "X-User-Email": userEmail,
          },
        }),
        axios.get(`/api/familymembers/${from.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "X-User-Email": userEmail,
          },
        })
      ]);

      setFiles(filesResponse.data.files);
      
      if (membersResponse.data?.members && Array.isArray(membersResponse.data.members)) {
        setFamilyMembers(membersResponse.data.members);
      } else {
        console.error("Unexpected family members response format:", membersResponse.data);
        setFamilyMembers([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      showSnackbar("Error loading data", "error");
    }
  }, [from?.id, GetAccessTokens, accounts]);

  useEffect(() => {
    if (!location.state?.from) {
      console.error("No family data provided");
      navigate("/families");
      return;
    }
  }, [location.state, navigate]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleFileUpload = async () => {
    if (!selectedFile || !selectedMember) {
      showSnackbar("Please select a file and family member", "error");
      return;
    }

    // Validate file size (max 100MB)
    if (selectedFile.size > 100 * 1024 * 1024) {
      showSnackbar("File size must be less than 100MB", "error");
      return;
    }

    // Validate file type
    const allowedTypes = [
      'image/jpeg', 
      'image/png', 
      'application/pdf', 
      'application/msword', 
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-powerpoint',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation'
    ];
    if (!allowedTypes.includes(selectedFile.type)) {
      showSnackbar("Invalid file type. Allowed types: JPG, PNG, PDF, DOC, DOCX, XLS, XLSX, PPT, PPTX", "error");
      return;
    }

    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('description', description.trim());

    try {
      const token = await GetAccessTokens();
      const userEmail = accounts[0]?.username;

      await axios.post(`/api/files/upload/${selectedMember}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "X-User-Email": userEmail,
          'Content-Type': 'multipart/form-data',
        },
      });

      handleCloseUploadDialog();
      showSnackbar("File uploaded successfully", "success");
      fetchData(); // Refresh the file list
    } catch (error) {
      console.error("Error uploading file:", error);
      const errorMessage = error.response?.data?.message || "Error uploading file";
      showSnackbar(errorMessage, "error");
    }
  };

  const handleDownload = async (file) => {
    setFileToDownload(file);
    setOpenDownloadDialog(true);
  };

  const handleDownloadConfirm = async () => {
    if (!fileToDownload) return;

    try {
      const token = await GetAccessTokens();
      const userEmail = accounts[0]?.username;

      const response = await axios.get(`/api/files/${fileToDownload.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "X-User-Email": userEmail,
        },
        responseType: 'blob',
      });

      // Create a URL for the blob
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileToDownload.filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
      
      setOpenDownloadDialog(false);
      setFileToDownload(null);
    } catch (error) {
      console.error("Error downloading file:", error);
      showSnackbar("Error downloading file", "error");
    }
  };

  const handleDownloadCancel = () => {
    setOpenDownloadDialog(false);
    setFileToDownload(null);
  };

  const handleDelete = async (fileId) => {
    try {
      const token = await GetAccessTokens();
      const userEmail = accounts[0]?.username;

      await axios.delete(`/api/files/${fileId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "X-User-Email": userEmail,
        },
      });

      // Optimistically update UI
      setFiles(prevFiles => prevFiles.filter(f => f.id !== fileId));
      showSnackbar("File deleted successfully", "success");
    } catch (error) {
      console.error("Error deleting file:", error);
      showSnackbar("Error deleting file. Please try again.", "error");
      // Refresh the file list in case of error
      fetchData();
    }
  };

  const handleEdit = async () => {
    if (!editingFile) return;

    const formData = new FormData();
    formData.append('description', description.trim());
    
    if (selectedFile) {
      // Validate file size (max 100MB)
      if (selectedFile.size > 100 * 1024 * 1024) {
        showSnackbar("File size must be less than 100MB", "error");
        return;
      }

      // Validate file type
      const allowedTypes = [
        'image/jpeg', 
        'image/png', 
        'application/pdf', 
        'application/msword', 
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation'
      ];
      if (!allowedTypes.includes(selectedFile.type)) {
        showSnackbar("Invalid file type. Allowed types: JPG, PNG, PDF, DOC, DOCX, XLS, XLSX, PPT, PPTX", "error");
        return;
      }
      
      formData.append('file', selectedFile);
    }

    try {
      const token = await GetAccessTokens();
      const userEmail = accounts[0]?.username;

      await axios.put(`/api/files/${editingFile.id}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "X-User-Email": userEmail,
          'Content-Type': 'multipart/form-data',
        },
      });

      handleCloseEditDialog();
      showSnackbar("File updated successfully", "success");
      fetchData(); // Refresh the file list
    } catch (error) {
      console.error("Error updating file:", error);
      const errorMessage = error.response?.data?.message || "Error updating file";
      showSnackbar(errorMessage, "error");
    }
  };

  const handleOpenUploadDialog = useCallback(() => {
    setSelectedFile(null);
    setSelectedMember('');
    setDescription('');
    setOpenUploadDialog(true);
  }, []);

  const handleCloseUploadDialog = useCallback(() => {
    setOpenUploadDialog(false);
    setSelectedFile(null);
    setSelectedMember('');
    setDescription('');
  }, []);

  const handleOpenEditDialog = useCallback((file) => {
    setEditingFile(file);
    setDescription(file.description || '');
    setSelectedFile(null);
    setOpenEditDialog(true);
  }, []);

  const handleCloseEditDialog = useCallback(() => {
    setOpenEditDialog(false);
    setEditingFile(null);
    setDescription('');
    setSelectedFile(null);
  }, []);

  const showSnackbar = useCallback((message, severity) => {
    setSnackbar({ open: true, message, severity });
  }, []);

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const formatFileSize = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  const onDragEnter = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  }, []);

  const onDragLeave = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  }, []);

  const onDragOver = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const onDrop = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const files = e.dataTransfer.files;
    if (files && files.length > 0) {
      setSelectedFile(files[0]);
    }
  }, []);

  return (
    <Container 
      sx={{ 
        py: 3,
        px: { xs: 2, sm: 3 },
        mx: 'auto',
        width: '80%'
      }}
    >
      <Box sx={{ maxWidth: '100%', mt: 4 }}>
        <Paper sx={{ p: 2, mb: 2 }}>
          <Grid container spacing={2} alignItems="center" sx={{ mb: 3 }}>
            <Grid item xs={12} sm="auto">
              <Button
                variant="outlined"
                onClick={() => navigate(-1)}
                startIcon={<ArrowBack />}
              >
                Terug naar Familie
              </Button>
            </Grid>
            <Grid item xs={12} sm="auto">
              <Typography variant="h5" component="h1">
                Files - {from?.familyName || 'Family'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm="auto" sx={{ marginLeft: 'auto' }}>
              <Button
                variant="contained"
                startIcon={<CloudUploadIcon />}
                onClick={handleOpenUploadDialog}
              >
                Upload Bestand
              </Button>
            </Grid>
          </Grid>

          <TableContainer sx={{ maxWidth: '100%', overflowX: 'auto' }}>
            <Table sx={{ 
              minWidth: { xs: '100%', sm: 480, md: 600 }
            }}>
              <TableHead>
                <TableRow>
                  <TableCell>Filename</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Size</TableCell>
                  <TableCell>Upload Date</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {files.map((file) => (
                  <TableRow key={file.id}>
                    <TableCell>{file.filename}</TableCell>
                    <TableCell>{file.description}</TableCell>
                    <TableCell>{formatFileSize(file.file_size)}</TableCell>
                    <TableCell>{dayjs(file.upload_date).format('DD-MM-YYYY HH:mm')}</TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleDownload(file)} title="Download">
                        <DownloadIcon />
                      </IconButton>
                      <IconButton onClick={() => handleOpenEditDialog(file)} title="Edit">
                        <EditIcon />
                      </IconButton>
                      <IconButton onClick={() => handleDelete(file.id)} title="Delete">
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
                {files.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      No files uploaded yet
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>

        {/* Upload Dialog */}
        <Dialog
          open={openUploadDialog}
          onClose={() => setOpenUploadDialog(false)}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>Upload Bestand</DialogTitle>
          <DialogContent>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                mt: 2,
              }}
            >
              <FormControl fullWidth>
                <InputLabel>Familielid</InputLabel>
                <Select
                  value={selectedMember}
                  onChange={(e) => setSelectedMember(e.target.value)}
                  label="Familielid"
                >
                  {familyMembers.map((member) => (
                    <MenuItem key={member.id} value={member.id}>
                      {member.familyMemberFirstName} {member.familyMemberSurname} ({member.familyMemberRelationship})
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Box
                onDragEnter={onDragEnter}
                onDragOver={onDragOver}
                onDragLeave={onDragLeave}
                onDrop={onDrop}
                sx={{
                  border: '2px dashed',
                  borderColor: isDragging ? 'primary.main' : 'grey.300',
                  borderRadius: 1,
                  p: 3,
                  textAlign: 'center',
                  bgcolor: isDragging ? 'action.hover' : 'background.paper',
                  cursor: 'pointer',
                  transition: 'all 0.2s ease',
                  '&:hover': {
                    borderColor: 'primary.main',
                    bgcolor: 'action.hover',
                  },
                }}
                onClick={() => document.getElementById('file-upload').click()}
              >
                <input
                  id="file-upload"
                  type="file"
                  onChange={(e) => setSelectedFile(e.target.files[0])}
                  style={{ display: 'none' }}
                />
                <CloudUploadIcon sx={{ fontSize: 48, color: 'primary.main', mb: 1 }} />
                <Typography variant="h6" gutterBottom>
                  Sleep een bestand hierheen
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  of klik om een bestand te selecteren
                </Typography>
                {selectedFile && (
                  <Typography variant="body2" color="primary" sx={{ mt: 1 }}>
                    Geselecteerd: {selectedFile.name}
                  </Typography>
                )}
              </Box>

              <TextField
                fullWidth
                label="Beschrijving"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                multiline
                rows={3}
              />
            </Box>
          </DialogContent>
          <DialogActions sx={{ p: 2.5, gap: 1 }}>
            <Button 
              onClick={() => {
                setOpenUploadDialog(false);
                setSelectedFile(null);
                setSelectedMember("");
                setDescription("");
              }}
              variant="outlined"
              color="primary"
              sx={{
                px: 3,
                py: 1,
                borderRadius: 1,
                textTransform: "none",
                fontSize: "1rem",
              }}
            >
              Terug
            </Button>
            <Button
              onClick={handleFileUpload}
              variant="contained"
              disabled={!selectedFile || !selectedMember}
              sx={{
                px: 3,
                py: 1,
                borderRadius: 1,
                textTransform: "none",
                fontSize: "1rem",
                boxShadow: 2,
                "&:hover": {
                  boxShadow: 4,
                  transform: "translateY(-1px)",
                },
                transition: "all 0.2s ease-in-out",
              }}
            >
              Uploaden
            </Button>
          </DialogActions>
        </Dialog>

        {/* Edit Dialog */}
        <Dialog open={openEditDialog} onClose={handleCloseEditDialog} maxWidth="sm" fullWidth>
          <DialogTitle>Edit File</DialogTitle>
          <DialogContent>
            <Box sx={{ mt: 2 }}>
              <TextField
                fullWidth
                multiline
                rows={3}
                label="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                sx={{ mb: 2 }}
              />

              <label htmlFor="edit-file">
                <Input
                  id="edit-file"
                  type="file"
                  onChange={(e) => setSelectedFile(e.target.files[0])}
                />
                <Button
                  variant="outlined"
                  component="span"
                  startIcon={<CloudUploadIcon />}
                  fullWidth
                >
                  Replace File
                </Button>
              </label>
              {selectedFile && (
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Selected: {selectedFile.name}
                </Typography>
              )}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseEditDialog}>Cancel</Button>
            <Button onClick={handleEdit} variant="contained">
              Save Changes
            </Button>
          </DialogActions>
        </Dialog>

        {/* Download Consent Dialog */}
        <Dialog
          open={openDownloadDialog}
          onClose={handleDownloadCancel}
          aria-labelledby="download-dialog-title"
          aria-describedby="download-dialog-description"
        >
          <DialogTitle id="download-dialog-title">
            Belangrijk bericht over gegevensbescherming
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="download-dialog-description">
              <Typography paragraph>
                U staat op het punt om een bestand te downloaden dat persoonlijke gegevens kan bevatten.
              </Typography>
              <Typography paragraph>
                Als u dit bestand downloadt op een apparaat dat geen eigendom is van VluchtelingenWerk, bent u verplicht om:
              </Typography>
              <Box component="ul" sx={{ mt: 1, mb: 2 }}>
                <Typography component="li">
                  Het bestand direct na gebruik te verwijderen van uw apparaat
                </Typography>
                <Typography component="li">
                  Het bestand niet door te sturen naar derden
                </Typography>
                <Typography component="li">
                  Het bestand niet op te slaan in persoonlijke cloud-opslag
                </Typography>
              </Box>
              <Typography paragraph color="error">
                Door op 'Akkoord' te klikken, bevestigt u dat u deze voorwaarden begrijpt en accepteert.
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDownloadCancel} color="primary">
              Annuleren
            </Button>
            <Button onClick={handleDownloadConfirm} color="primary" variant="contained">
              Akkoord
            </Button>
          </DialogActions>
        </Dialog>

        {/* Snackbar for notifications */}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Box>
    </Container>
  );
}

export default FamilyFiles;
