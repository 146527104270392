import React from "react";
import Container from "@mui/material/Container";
import { Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import axios from "axios";
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import VersionInfo from "../utils/Version";

function Homepage() {
  const version = VersionInfo.version;
  const { instance, accounts } = useMsal();

  const GetAccessTokens = async () => {
    const request = {
      scopes: ["api://e8ee6ebf-41ff-4a7a-8d4b-2b2b4aa05d8e/User.Read"],
      account: accounts[0],
    };
    console.log(request);
    try {
      const response = await instance.acquireTokenSilent(request);
      console.log(response);
      return response.accessToken;
    } catch (e) {
      if (e instanceof InteractionRequiredAuthError) {
        return instance.acquireTokenRedirect(request);
      }
    }
  };
  const getAccessTokens = GetAccessTokens();
  const handleOnClick = async () => {
    try {
      const token = await getAccessTokens;
      console.log(token);
      await axios({
        method: "GET",
        url: "/api/testsecure",
        headers: { Authorization: "Bearer " + token },
      })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      <Container>
        <Box sx={{ maxWidth: "80%" }}>
          <Typography variant="h4" sx={{ marginTop: 10 }}>
            Stichting VluchtelingenWerkgroep Wijk bij Duurstede | ClientGids
            {" versie " + version.release} d.d. {version.releasedate}
          </Typography>
          <Typography variant="body1">
            Applicatie versie {version.release}
            <br />
            Omgeving: {version.environment}
          </Typography>
          <Typography variant="body1">
            In de versie van de applicatie zijn de volgende functie beschikbaar:
            <ul>
              <li>
                Het raadplegen van de lijst van families in actieve begeleiding
                bij Vluchtelingenwerkgroep Wijk bij Duurstede
              </li>
              <li>
                Het raadplegen van de lijst van families die je zelf in
                begeleiding hebt (dit zijn dus echt alleen de gezinnen waar jij
                als begeleider gekoppeld staat). Let op, er is nu geen actieve
                koppeling voor woonbegeleiders.
              </li>
              <li>
                Het toevoegen van nieuwe families en familieleden binnen die
                families
              </li>
              <li>Het bewerken van de gegevens van families en familieleden</li>
              <li>
                Het bewerken en toevoegen van logboekitems per familie,
                gekoppeld aan de individuele familieleden
              </li>

              <li>
                Het onderhouden van de checklist per gezinslid (let op, een
                checklist wordt nu niet meer automatisch aangemaakt, maar moet
                worden aangemaakt door de gebruiker zelf)
              </li>
              <li>
                In de checklist kun je nu items op niet van toepassing zetten,
                daarmee verdwijnen ze ook{" "}
              </li>
              <li>Het aanpassen van persoonsgegevens</li>
              <li>Het exporteren van de gegevens naar een Excel bestand</li>
              <li>Het toevoegen van bestanden aan familieleden en aan het logboek</li>

              <li>het onderhouden en toevoegen van servicepartijen</li>
            </ul>
          </Typography>
        </Box>
      </Container>
    </div>
  );
}

export default Homepage;
