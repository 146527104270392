import React, { useState, useEffect, useCallback, useMemo } from "react";
import { 
  Typography, 
  Box, 
  Button, 
  Container, 
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Card,
  useMediaQuery,
  Stack,
  Chip,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton
} from "@mui/material";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import DeleteIcon from '@mui/icons-material/Delete';
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Spinner from "../components/Spinner";
import ExportFamiliesButton from "../utils/ExportFamiliesButton";
import dayjs from 'dayjs';
import 'dayjs/locale/nl';
import { protectedResources } from "../authentication/authConfig";
import { useAuth } from "../authentication/useAuth";

// Initialize dayjs with Dutch locale
dayjs.locale('nl');

function sleep(milliseconds) {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
}

function Families() {
  const [families, setFamilies] = useState([]);
  const [isloading, setIsLoading] = useState(true);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deletePassword, setDeletePassword] = useState('');
  const [selectedFamilyId, setSelectedFamilyId] = useState(null);
  const [error, setError] = useState('');
  const { getAccessToken } = useAuth();



  const orderToDate = useCallback((data) => {
    return data.sort((a, b) => {
      return new Date(b.familyDateInflo) - new Date(a.familyDateInflo);
    });
  }, []);

  const fetchData = useCallback(async () => {
    try {
      const token = await getAccessToken();
      const response = await axios({
        method: "GET",
        url: "/api/families",
        headers: { Authorization: "Bearer " + token },
      });
      
      const data = response.data.families;
      setFamilies(orderToDate(data));
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching families:", error);
      setError("Error loading families");
      setIsLoading(false);
    }
  }, [orderToDate]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleDeleteClick = (familyId) => {
    setSelectedFamilyId(familyId);
    setDeleteDialogOpen(true);
    setDeletePassword('');
    setError('');
  };

  const handleDeleteConfirm = async () => {
    try {
      const token = await getAccessToken();
      if (!token) {
        setError('Authentication error: No token available');
        return;
      }

      console.log('Sending delete request with token:', token); // Debug log

      const response = await axios.delete(`/api/deletefamily/${selectedFamilyId}`, {
        headers: { 
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        data: { password: deletePassword }
      });

      if (response.status === 200) {
        setFamilies(families.filter(family => family.id !== selectedFamilyId));
        setDeleteDialogOpen(false);
        setError('');
      }
    } catch (error) {
      console.error('Delete error:', error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        setError(error.response.data?.message || 'Server error');
        console.log('Error response:', error.response);
      } else if (error.request) {
        // The request was made but no response was received
        setError('No response from server');
        console.log('Error request:', error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        setError('Error setting up request');
        console.log('Error setup:', error.message);
      }
    }
  };

  const content = useMemo(() => {
    if (isloading) return <Spinner />;
    if (error) return <Typography color="error">{error}</Typography>;
    return <Content families={families} handleDeleteClick={handleDeleteClick} />;
  }, [isloading, error, families, handleDeleteClick]);

  return (
    <Container 
      maxWidth="lg" 
      sx={{ 
        py: 3,
        px: { xs: 2, sm: 3 },
        mx: 'auto',
        width: '80%'  
      }}
    >
      <Box sx={{ 
        width: '100%',
        maxWidth: '100%',
        overflowX: 'auto'
      }}>
        {content}
      </Box>

      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle>Delete Family</DialogTitle>
        <DialogContent>
          <Typography color="error" gutterBottom>
            Warning: This action cannot be undone. All family data will be permanently deleted.
          </Typography>
          <TextField
            autoFocus
            margin="dense"
            label="Wachtwoord voor verwijderen"
            type="password"
            fullWidth
            variant="outlined"
            value={deletePassword}
            onChange={(e) => setDeletePassword(e.target.value)}
            error={!!error}
            helperText={error || "Voer het wachtwoord in om de familie te verwijderen"}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button 
            onClick={handleDeleteConfirm} 
            color="error" 
            variant="contained"
            disabled={!deletePassword}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

const Content = React.memo(({ families, handleDeleteClick }) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:600px)');
  const isTablet = useMediaQuery('(max-width:900px)');
  const [filterValue, setFilterValue] = useState("");

  const handleDoubleClick = useCallback((client) => {
    navigate("/familycard", { state: { from: client } });
  }, [navigate]);

  const handleFilterChange = useCallback((e) => {
    setFilterValue(e.target.value);
  }, []);

  const filteredFamilies = useMemo(() => {
    const filterValueLowerCase = filterValue.toLowerCase();
    return families.filter((row) => {
      return (
        row.familyName.toLowerCase().includes(filterValueLowerCase) ||
        row.familyStreet.toLowerCase().includes(filterValueLowerCase) ||
        row.first_nameMB.toLowerCase().includes(filterValueLowerCase)
      );
    });
  }, [families, filterValue]);

  // Memoize FamilyCard component
  const FamilyCard = React.memo(({ family }) => (
    <Card 
      sx={{ 
        p: 2, 
        mb: 2,
      }}
    >
      <Stack spacing={1}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
          <Box sx={{ cursor: 'pointer' }} onClick={() => handleDoubleClick(family)}>
            <Typography variant="h6">{family.familyName}</Typography>
          </Box>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              handleDeleteClick(family.id);
            }}
            color="error"
            size="small"
          >
            <DeleteIcon />
          </IconButton>
        </Box>
        <Box sx={{ cursor: 'pointer' }} onClick={() => handleDoubleClick(family)}>
          <Typography variant="body2" color="text.secondary">
            {family.familyStreet} {family.familyHousenumber}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {family.familyPostalcode} {family.familyCity}
          </Typography>
          <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mt: 1 }}>
            <Chip 
              size="small" 
              label={`Plaatsing: ${family.familyDateInflo ? dayjs(family.familyDateInflo).format('D MMM YYYY') : '-'}`}
              color="default"
            />
            <Chip 
              size="small" 
              label={`Gezinsleden: ${family.member_count}`}
              color="primary"
            />
            <Chip 
              size="small" 
              label={`Begeleider: ${family.first_nameMB}`}
              color="secondary"
            />
          </Box>
        </Box>
      </Stack>
    </Card>
  ));

  FamilyCard.displayName = 'FamilyCard';

  return (
    <Box sx={{ maxWidth: '100%' }}>
      <Box sx={{ mb: 4, mt: 10 }}>
        <Typography variant="h4" gutterBottom>
          Overzicht families in begeleiding
        </Typography>
        
        <Typography variant="body1" color="text.secondary" gutterBottom>
          {isMobile ? 'Tik op een familie' : 'Dubbelklik op een familie'} om naar de familiegegevens te gaan.
        </Typography>

        <Box sx={{ 
          mt: 3, 
          display: 'flex', 
          flexDirection: { xs: 'column', sm: 'row' }, 
          gap: 2,
          alignItems: { xs: 'stretch', sm: 'center' },
          maxWidth: '100%'
        }}>
          <TextField
            fullWidth
            label="Zoeken"
            variant="outlined"
            value={filterValue}
            onChange={handleFilterChange}
            InputProps={{
              startAdornment: <SearchIcon color="action" sx={{ mr: 1 }} />,
            }}
            sx={{ 
              maxWidth: { sm: '320px' },  
              '& .MuiOutlinedInput-root': {
                borderRadius: 2,
                transition: 'all 0.2s ease-in-out',
                '&:hover': {
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderWidth: '2px',
                  },
                },
                '&.Mui-focused': {
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderWidth: '2px',
                  },
                },
              },
            }}
          />
          
          <Box sx={{ 
            display: 'flex', 
            gap: 2,
            flexDirection: { xs: 'column', sm: 'row' }
          }}>
            <Tooltip title="Exporteer familiegegevens">
              <Paper
                elevation={2}
                sx={{
                  borderRadius: 2,
                  overflow: 'hidden',
                  transition: 'all 0.2s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: 3,
                  },
                }}
              >
                <ExportFamiliesButton />
              </Paper>
            </Tooltip>

            <Tooltip title="Voeg nieuwe familie toe">
              <Paper
                elevation={2}
                sx={{
                  borderRadius: 2,
                  overflow: 'hidden',
                  transition: 'all 0.2s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: 3,
                  },
                }}
              >
                <Button
                  component={Link}
                  to="/newfamily"
                  fullWidth={isMobile}
                  startIcon={<PersonAddAltIcon />}
                  sx={{
                    px: 3,
                    py: 1.5,
                    bgcolor: 'primary.lighter',
                    color: 'primary.main',
                    '&:hover': {
                      bgcolor: 'primary.light',
                    },
                  }}
                >
                  <Typography 
                    variant="button" 
                    sx={{ 
                      textTransform: 'none',
                      fontWeight: 500,
                      fontSize: '0.9rem',
                    }}
                  >
                    Familie toevoegen
                  </Typography>
                </Button>
              </Paper>
            </Tooltip>
          </Box>
        </Box>
      </Box>

      {isMobile ? (
        <Box sx={{ maxWidth: '100%' }}>
          {filteredFamilies.map((family) => (
            <FamilyCard key={family.id} family={family} />
          ))}
        </Box>
      ) : (
        <TableContainer 
          component={Paper} 
          elevation={2}
          sx={{ 
            maxWidth: '100%',
            overflowX: 'auto',
            borderRadius: 2,
            mb: 4,
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Familie</TableCell>
                <TableCell>Adres</TableCell>
                <TableCell>Plaatsing</TableCell>
                <TableCell>Gezinsleden</TableCell>
                <TableCell>Begeleider</TableCell>
                <TableCell align="right">Acties</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredFamilies.map((family) => (
                <TableRow
                  key={family.id}
                  hover
                  onClick={() => handleDoubleClick(family)}
                  sx={{ cursor: 'pointer' }}
                >
                  <TableCell>{family.familyName}</TableCell>
                  <TableCell>
                    {family.familyStreet} {family.familyHousenumber}<br />
                    {family.familyPostalcode} {family.familyCity}
                  </TableCell>
                  <TableCell>
                    {family.familyDateInflo ? dayjs(family.familyDateInflo).format('D MMM YYYY') : '-'}
                  </TableCell>
                  <TableCell>{family.member_count}</TableCell>
                  <TableCell>{family.first_nameMB}</TableCell>
                  <TableCell align="right">
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteClick(family.id);
                      }}
                      color="error"
                      size="small"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
});

export default Families;
