import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { Box, Button, Container, IconButton } from "@mui/material";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import axios from "axios";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Spinner from "../components/Spinner";
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";

function sleep(milliseconds) {
	const date = Date.now();
	let currentDate = null;
	do {
		currentDate = Date.now();
	} while (currentDate - date < milliseconds);
}

function Families() {
	const [families, setFamilies] = useState([]);
	const [isloading, setIsLoading] = useState(true);

	const { instance, accounts } = useMsal();

	const GetAccessTokens = async () => {
		const request = {
			scopes: ["api://e8ee6ebf-41ff-4a7a-8d4b-2b2b4aa05d8e/User.Read"],
			account: accounts[0],
		};
		console.log(request);
		try {
			const response = await instance.acquireTokenSilent(request);
			console.log(response);
			return response.accessToken;
		} catch (e) {
			if (e instanceof InteractionRequiredAuthError) {
				return instance.acquireTokenRedirect(request);
			}
		}
	};
	const getAccessTokens = GetAccessTokens();

	const fetchData = async () => {
		const token = await getAccessTokens;
		console.log(token);
		axios({
			method: "GET",
			url: "/api/families",
			headers: { Authorization: "Bearer " + token },
		}).then((response) => {
			setFamilies(response.data.families);
			sleep(1000);
			setIsLoading(false);
		});
	};

	useEffect(() => {
		fetchData();
	}, []);

	return (
		<Container>
			{isloading ? (
				<Spinner message={"Familiegegevens ophalen..."} />
			) : (
				<Content props={{ families, accounts }} />
			)}
		</Container>
	);
}

export default Families;

const Content = ({ props }) => {
	const navigate = useNavigate();
	const handleDoubleClick = (client) => {
		navigate("/familycard", { state: { from: client } });
	};
	const filteredFamilies = props.families.filter(
		(family) => family.mb_email === props.accounts[0].username.toLowerCase()
	);
	return (
		<React.Fragment>
			<Box sx={{ maxWidth: '100%', mt: 10 }}>
				<Typography variant="h4" color="initial" sx={{ marginTop: 10 }}>
					Mijn families in begeleiding
				</Typography>
				<Typography variant="body1" color="initial" sx={{ marginTop: 2 }}>
					Dubbelklik op een familie om naar de familiegegevens te gaan.
				</Typography>
				<IconButton color="success" component={Link} to="/newfamily">
					<PersonAddAltIcon /> Family toevoegen
				</IconButton>
			</Box>
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 650 }} aria-label="simple table">
					<TableHead>
						<TableRow sx={{ color: "white" }}>
							<TableCell sx={{ color: "white" }}>FamilieId</TableCell>
							<TableCell sx={{ color: "white" }} align="left">
								Familie naam
							</TableCell>
							<TableCell sx={{ color: "white" }} align="left">
								Straat
							</TableCell>
							<TableCell sx={{ color: "white" }} align="left">
								Huisnummer
							</TableCell>
							<TableCell sx={{ color: "white" }} align="left">
								Postcode
							</TableCell>
							<TableCell sx={{ color: "white" }} align="left">
								Plaats
							</TableCell>
							<TableCell sx={{ color: "white" }} align="left">
								Begeleider
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{filteredFamilies.map((row) => (
							<TableRow
								key={row.id}
								sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
								onDoubleClick={() => handleDoubleClick(row)}
								hover
							>
								<TableCell>{row.id}</TableCell>
								<TableCell align="left">{row.familyName}</TableCell>
								<TableCell align="left">{row.familyStreet}</TableCell>
								<TableCell align="left">{row.familyHousenumber}</TableCell>
								<TableCell align="left">{row.familyPostalcode}</TableCell>
								<TableCell align="left">{row.familyCity}</TableCell>
								<TableCell align="left">{row.first_nameMB}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</React.Fragment>
	);
};
